// import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

export default {
  loadBaseInfo({ state, commit, rootState }) {
    return new Promise((resolve, reject) => {
      axios.get('/auth/me')
        .then(respMe => {
          let userId = respMe.data.data.user_id;
          axios.get('/users/' + userId)
            .then(respUser => {
              let data = respUser.data
              let token = localStorage.getItem('token')
              let user = data.user
              let trainer = data.trainers && data.trainers.length > 0 ? data.trainers[0] : null
              let role = trainer ? (trainer.super_admin ? "admin" : "trainer") : ""
              let organization = data.organizations && data.organizations.length?data.organizations[0]:null
              // console.log(user, trainer, role, organization)
              if (user && user.super_admin) role = "super"
              if (!organization || !organization.id) {
                reject("请联系管理员激活账户。");
              } else {
                // commit('UPDATE_AUTHENTICATED_USER', user)
                commit('auth_success', { token, user, rootState, state, role, trainer, organization })
                commit('update_array', { name: "trainers", data: data.trainers }, { root: true })
                commit('update_array', { name: "organizations", data: data.organizations }, { root: true })

                resolve(respUser)
              }
            })
        })
    })
  },

  coachLogin({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      // let myRouter = router
      commit('auth_request')
      axios.post('/oauth/token', user.userDetails)
        .then(resp => {
          if (resp.data.success != 0 || !resp.data.trainer_id) {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(resp.data.msg)
          } else {
            const token = resp.data.token
            // const user = resp.data.user
            localStorage.setItem('token', token)
            // localStorage.setItem('userInfo', JSON.stringify(user))
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
            dispatch("loadBaseInfo")
              .then(() => { resolve(resp) })
          }
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
    })
  },

  // coachLogin({ state, commit, rootState }, user) {
  //   return new Promise((resolve, reject) => {
  //     // let myRouter = router
  //     commit('auth_request')
  //     axios.post('/oauth/token', user.userDetails)
  //       .then(resp => {
  //         if (resp.data.success != 0 || !resp.data.trainer_id) {
  //           commit('auth_error')
  //           localStorage.removeItem('token')
  //           reject(resp.data.msg)
  //         } else {
  //           const token = resp.data.token
  //           // const user = resp.data.user
  //           localStorage.setItem('token', token)
  //           // localStorage.setItem('userInfo', JSON.stringify(user))
  //           axios.defaults.headers.common['Authorization'] = "Bearer " + token
  //           axios.get('/auth/me')
  //             .then(rep => {
  //               let userId = rep.data.data.user_id;
  //               axios.get('/users/' + userId)
  //                 .then(rep => {
  //                   let data = rep.data
  //                   let user = data.user
  //                   let trainer = data.trainers.length > 0 ? data.trainers[0] : null
  //                   let role = trainer ? (trainer.super_admin ? "admin" : "trainer") : ""
  //                   let organization = data.organizations[0]
  //                   if (!organization.id) {
  //                     reject("请联系管理员激活账户。");
  //                   }
  //                   //let superAdmin = user ? user.super_admin : false
  //                   if (user.super_admin) role = "super"
  //                   commit('UPDATE_AUTHENTICATED_USER', user)
  //                   commit('auth_success', { token, user, rootState, state, role, trainer, organization })
  //                   commit('update_array', { name: "trainers", data: data.trainers }, { root: true })
  //                   commit('update_array', { name: "organizations", data: data.organizations }, { root: true })

  //                   // myRouter.push(myRouter.currentRoute.query.to || '/')
  //                   resolve(resp)
  //                 })
  //             })
  //         }
  //       })
  //       .catch(err => {
  //         commit('auth_error')
  //         localStorage.removeItem('token')
  //         reject(err)
  //       })
  //   })
  // },

  adminLogin({ commit, rootState }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/admin/login', user)
        .then(resp => {
          const token = resp.data.data.token
          const user = resp.data.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userInfo', JSON.stringify(user))
          axios.defaults.headers.common['Authorization'] = "Bearer " + token
          commit('auth_success', { token, user, rootState })
          router.push(router.currentRoute.query.to || '/');
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
    })
  },

  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: '/api/register', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.data.token
          const user = resp.data.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userInfo', JSON.stringify(user))
          axios.defaults.headers.common['Authorization'] = "Bearer " + token
          commit('auth_success', token, user)
          router.push(router.currentRoute.query.to || '/');
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err)
          localStorage.removeItem('token')
          reject(err)
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userRole')
      localStorage.removeItem('timestamp')
      // delete axios.defaults.headers.common['Authorization']
      resolve()
      //if (false) reject();
    })
  },

  updateAuthenticatedUser({ commit }, payload) {
    commit('UPDATE_AUTHENTICATED_USER', payload)
  }
}
