
// if (!Date.prototype.getWeek) Date.prototype.getWeek = function () {
//   var date = new Date(this.getTime());
//   date.setHours(0, 0, 0, 0);
//   // Thursday in current week decides the year.
//   date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
//   // January 4 is always in week 1.
//   var week1 = new Date(date.getFullYear(), 0, 4);
//   // Adjust to Thursday in week 1 and count number of weeks from date to week1.
//   return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
// }

// /**
//  * Returns the week number for this date.  dowOffset is the day of week the week
//  * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
//  * the week returned is the ISO 8601 week number.
//  * @param int dowOffset
//  * @return int
//  */
if (!Date.prototype.getWeek) Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

      dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
      var newYear = new Date(this.getFullYear(),0,1);
      var day = newYear.getDay() - dowOffset; //the day of week the year begins on
      day = (day >= 0 ? day : day + 7);
      var daynum = Math.floor((this.getTime() - newYear.getTime() -
      (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
      var weeknum;
      //if the year starts before the middle of a week
      if(day < 4) {
          weeknum = Math.floor((daynum+day-1)/7) + 1;
          if(weeknum > 52) {
              var nYear = new Date(this.getFullYear() + 1,0,1);
              var nday = nYear.getDay() - dowOffset;
              nday = nday >= 0 ? nday : nday + 7;
              /*if the next year starts before the middle of
                the week, it is week #1 of that year*/
              weeknum = nday < 4 ? 1 : 53;
          }
      }
      else {
          weeknum = Math.floor((daynum+day-1)/7);
      }
      return weeknum;
  };

if (!Date.prototype.getDateRangeOfWeek) Date.prototype.getDateRangeOfWeek = function () {
  let weekNo = this.getWeek();
  let y = this.getFullYear();
  if (weekNo==1 && this.getMonth()>1) y+=1;

  var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
  d1 = new Date('' + y + '');
  numOfdaysPastSinceLastMonday = d1.getDay() - 1;
  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  d1.setDate(d1.getDate() + (7 * (weekNo - d1.getWeek())));
  rangeIsFrom = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  d1.setDate(d1.getDate() + 6);
  rangeIsTo = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());

  return {
    firstDay: rangeIsFrom,
    lastDay: rangeIsTo
  }
};

if (!Date.prototype.addDays) Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

/**
 * how many days passed
 * due format = "yyyy-MM-dd"
 */
if (!Date.prototype.DaysPassed ) Date.prototype.DaysPassed = function(due){
  let dateDue = new Date(due);
  let dateThis = new Date(this.valueOf());
  let Difference_In_Time = dateThis.getTime() - dateDue.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.trunc(Difference_In_Days);
}

export default {
  getMonthWeekRange(dateVal) {
    if (!(dateVal instanceof Date)) dateVal = new Date();

    let firstDay = new Date(dateVal.getFullYear(), dateVal.getMonth(), 1);
    let lastDay = new Date(dateVal.getFullYear(), dateVal.getMonth() + 1, 0);

    return {
      firstDay: firstDay.getDateRangeOfWeek().firstDay,
      lastDay: lastDay.getDateRangeOfWeek().lastDay
    }
  },

  getDueDate(day) {
    return day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate()
  },

  getDaysPassed(due){
    let today = new Date();
    return today.DaysPassed(due);
  }
}
