import Mock from 'mockjs'

const organization = {
  "id": 1,
  "name": "Avocado Method牛油果体适能",
  "homepage_url": "",
  "active_clients_count": 58,
  "can_whitelabel": true,
  "theme": "green-dk",
  "subdomain": null,
  "links": {
    "documents": "/api/organizations/1/documents",
    "trainers": "/api/organizations/1/trainers",
    "clients": "/api/organization/clients"
  },
  "owner_id": 2,
  "pending_owner_id": null,
  "image_id": 2,
  "stripe_account_id": null
}

const organizations = [organization]

const rootUser = {
  "id": 1,
  "has_trainer": false,
  "email": "rock.xu@gmail.com",
  "name": "rock xu",
  "units": "imperial",
  "height": 5,
  "weight": 128.0,
  "birthday": "1977/02/19",
  "location": "Dlian, China",
  "gender": "male",
  "skype": "",
  "timezone": "Beijing",
  "timezone_offset": 8,
  "is_online": false,
  "has_pending_invite": false,
  "invite_token": null,
  "invite_accepted_at": null,
  "in_person": false,
  "feature_flags": {},
  "demo": false,
  "phone_number": '13812345671',
  "image_id": 1,
  "image_url": '/profiles/user-01.jpg',
}

const orgTrainerUser = {
  "id": 2,
  "has_trainer": false,
  "email": "vickieou@hotmail.com",
  "name": "Vicky ou",
  "units": "imperial",
  "height": 5,
  "weight": 128.0,
  "birthday": "2019/05/09",
  "location": "Shenzhen, China",
  "gender": "female",
  "skype": "",
  "timezone": "Beijing",
  "timezone_offset": 8,
  "is_online": false,
  "has_pending_invite": false,
  "invite_token": null,
  "invite_accepted_at": "2019-07-24T14:45:28.740Z",
  "in_person": false,
  "feature_flags": {},
  "demo": false,
  "phone_number": '13812345672',
  "image_id": 2,
  "image_url": '/profiles/user-02.jpg',
}

const clientUser = {
  "id": 3,
  "has_trainer": true,
  "email": "273885651@qq.com",
  "name": "Phoebe Jiang",
  "units": "imperial",
  "height": 60,
  "weight": 124.0,
  "birthday": "1988/08/17",
  "location": "SZ",
  "gender": "female",
  "skype": "",
  "timezone": "Beijing",
  "timezone_offset": 8,
  "is_online": false,
  "has_pending_invite": false,
  "invite_token": null,
  "invite_accepted_at": "2019-05-03T12:14:07.037Z",
  "in_person": false,
  "feature_flags": {},
  "demo": false,
  "phone_number": '13812345673',
  "image_id": 3,
  "image_url": '/profiles/user-03.jpg',
  "trainer_id": 2,
};

const client = {
  "id": 1,
  "settings": {
    "dailyWorkoutEmails": true,
    "missedWorkoutEmails": true,
    "newMessageEmails": true,
    "newCommentEmails": true,
    "weeklyDigest": true,
    "overrideDefaults": false,
    "workoutsThreshold": 0,
    "currentWeekOnly": false
  },
  "slug": "phoebe-jiang",
  "due": new Date().toJSON().slice(0, 10),
  "due_date_locked": false,
  "state": "active",
  "missed_sessions_count": 0,
  "limitations": null,
  "equipment": null,
  "equipment_attachments": [
    {
      "name": "dp1y689uqi1u0.cloudfront-14.png",
      "size": 1121818,
      "type": "image/png",
      "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b97b8d74-ee85-4421-b9c5-45ae34ca95a9/dp1y689uqi1u0cloudfront14.png"
    }
  ],
  "goals": "减少体脂：低于24%\n臀部肌肉\n\n第一阶段：髋部灵活度 / 肩膀稳定性\n第二阶段：硬拉力量 60KG@8RM\n第三阶段：背蹲 60KG@8RM",
  "updated_at": "2019-07-25T01:23:10.862Z",
  "completed_workouts_count": 40,
  "client_type": "remote",
  "links": {
    "assessments": "/api/clients/357155/assessments",
    "assessment_groups": "/api/clients/357155/assessment_groups",
    "conversation": "/api/clients/357155/conversation",
    "daily_nutrition_logs": "/api/clients/357155/daily_nutrition_logs",
    "notes": "/api/clients/357155/notes",
    "nutrition_plan": "/api/clients/357155/nutrition_plan",
    "photo_sessions": "/api/clients/357155/photo_sessions",
    "skeletons": "/api/clients/357155/skeletons",
    "weight_trackings": "/api/clients/357155/weight_trackings",
    "workouts": "/api/workouts?client_id=357155",
    "stripe_subscriptions": "/api/clients/357155/stripe_subscriptions"
  },
  "compliance_rate_for_7_days": "1.0",
  "compliance_rate_for_30_days": "0.852941176470588235",
  "compliance_rate_for_90_days": "0.931428571428571429",
  "hide_from_feed": false,
  "organization_id": 1,
  "trainer_id": 2,
  "user_id": 3,
  "stripe_payment_source_id": null,
  "group_assignment_ids": []
}

const clients = [client]

const users = [rootUser, orgTrainerUser, clientUser]

const trainer = {
  "id": 2,
  "active_clients_count": 1,
  "active_client_subscription_count": 0,
  "billing_id": "cus_EFFel7jXq13stJ",
  "can_add_clients": true,
  "clients_trained": "6-20 clients",
  "coupon": null,
  "coupon_rate": 1.0,
  "created_at": "2018-12-15T16:03:41.642Z",
  "discount": null,
  "free": false,
  "has_access": true,
  "has_completed_onboarding": true,
  "in_trial_period": false,
  "is_organization_owner": true,
  "is_organization_pending_owner": false,
  "links": {
    "clients": "/api/trainers/1/clients",
    "subscription": "/api/subscriptions/7292"
  },
  "max_clients": 75,
  "notify_every": "every hour",
  "offline_overridden": true,
  "organization_id": 1,
  "referral_code": "beab47aecf",
  "settings": {
    "conversationShowUnreads": false,
    "editorShowSkeletons": true,
    "editorShowResults": true,
    "editorShowComments": true,
    "editorTruncateText": false,
    "editorFontSize": "small",
    "generateWorkoutNotifications": true,
    "generateAssessmentNotifications": false,
    "generateBenchmarkNotifications": true,
    "generateProgressPictureNotifications": false,
    "generatePaymentMethodCreate": false,
    "emailPaymentMethodCreate": false,
    "generatePaymentMade": false,
    "emailPaymentMade": false,
    "newMessage": false,
    "newComment": false,
    "pastDueThreshold": "3",
    "needsAttentionThreshold": 1,
    "soundOnNotification": true,
    "soundOnMessage": false,
    "workCanWait": false,
    "workCanWaitMon": true,
    "workCanWaitTue": true,
    "workCanWaitWed": true,
    "workCanWaitThu": true,
    "workCanWaitFri": true,
    "workCanWaitSat": false,
    "workCanWaitSun": false,
    "workCanWaitStartTime": "09",
    "workCanWaitEndTime": "17",
    "showUnreads": true,
    "clientWorkoutsThreshold": "2",
    "clientCurrentWeekOnly": false,
    "includePartnerVideos": false
  },
  "super_admin": true,
  "tour_step": 2,
  "trial_expires_at": "2030-12-29T16:03:41.637Z",
  "user_id": 2,
  "was_referred": false,
  "invoice_ids": []
}

const trainers = [trainer]

const userImage1 = {
  "id": 1,
  "image_url": "/profiles/user-01.jpg",
  "mime_type": "image/png",
  "file_size": 23021,
  "parent": {
    "type": "organization",
    "id": 1
  },
  "uploaded_by_id": 1
}
const userImage2 = {
  "id": 2,
  "image_url": "/profiles/user-02.jpg",
  "mime_type": "image/png",
  "file_size": 23021,
  "parent": {
    "type": "organization",
    "id": 2
  },
  "uploaded_by_id": 1
}
const userImage3 = {
  "id": 3,
  "image_url": "/profiles/user-03.jpg",
  "mime_type": "image/png",
  "file_size": 23021,
  "parent": {
    "type": "organization",
    "id": 3
  },
  "uploaded_by_id": 3
}
const images = [userImage1, userImage2, userImage3]

const workouts = [
  {
    id: 1,
    client_id: 1,
    due: new Date().toJSON().slice(0, 10),
    state: 'pending',
    rest_day: false,
    title: 'W1-D3',
    warmup: null,
    warmup_selected_exercises: [
      {
        id: 1,
        name: 'name',
      }
    ],
    workout_items: [{
      name: '2轮',
      info: '背墙肩胛骨激活1 20次',
      result: null,
      exercise_id: null,
      attachments: [],

    }],
    cooldown: null,
    comment_ids: [],
    comments: [],
  },
]

const workout_items = [
  {
    "id": 139403715,
    "workout_id": 31579291,
    "name": "高脚杯下蹲 Goblet Squat",
    "info": "4组x12次，强度60%，节奏211，间歇60s\n坐到一个膝盖左右高度的板凳上然后站起\n髋关节积极外旋blet",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-24T07:51:01.784Z",
    "attachments": [],
    "exercise_id": 482503,
    "request_video": false
  },
  {
    "id": 139403716,
    "workout_id": 31579291,
    "name": "俯卧撑(板凳上) Bench Push up",
    "info": "4组x12次，强度60%，节奏211，间歇60s\n肩胛骨保持积极下沉Push ",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-24T07:51:01.790Z",
    "attachments": [],
    "exercise_id": 497640,
    "request_video": false
  },
  {
    "id": 139403717,
    "workout_id": 31579291,
    "name": "臀桥 Hip Press",
    "info": "3组x15次，强度50%，节奏211，间歇60s\n调整脚跟和臀部的距离尽可能使臀部发力\n",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-24T07:51:01.802Z",
    "attachments": [],
    "exercise_id": 528913,
    "request_video": false
  },
  {
    "id": 139403718,
    "workout_id": 31579291,
    "name": "徒手臂屈伸 Body Weight Triceps Extension ",
    "info": "3组x15次，强度50%，节奏211，间歇60s\n肩胛骨积极后缩",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-24T07:51:01.810Z",
    "attachments": [],
    "exercise_id": 527600,
    "request_video": false
  },
  {
    "id": 139404043,
    "workout_id": 31579291,
    "name": "死虫 Dead Bug",
    "info": "3组x10次 左/右，间歇60s\n腰部紧贴地面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-06-24T07:53:15.538Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 139404044,
    "workout_id": 31579291,
    "name": "躯干保持 Hollow Body",
    "info": "3组x20s，间歇60s\n骨盆积极后倾",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-06-24T07:53:15.539Z",
    "attachments": [],
    "exercise_id": 532256,
    "request_video": false
  },
  {
    "id": 139404326,
    "workout_id": 31579444,
    "name": "臀桥 Hip Press",
    "info": "5组x15次，节奏313，间歇60s\n骨盆转动向上, 并且在最高位保持伸展Hi",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-24T07:55:15.137Z",
    "attachments": [],
    "exercise_id": 528913,
    "request_video": false
  },
  {
    "id": 139404533,
    "workout_id": 31579444,
    "name": "哑铃二头弯举 DB Biceps Curl",
    "info": "4组x15次，轻重量，节奏212，间歇60s\n保持动作幅度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-24T07:57:33.439Z",
    "attachments": [],
    "exercise_id": 541347,
    "request_video": false
  },
  {
    "id": 139404534,
    "workout_id": 31579444,
    "name": "躯干保持 Hollow Body",
    "info": "4x20s，间歇60s\n腰部紧贴地面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-24T07:57:33.442Z",
    "attachments": [],
    "exercise_id": 532256,
    "request_video": false
  },
  {
    "id": 139404535,
    "workout_id": 31579444,
    "name": "有氧训练",
    "info": "(跑/爬坡/椭圆机/划船机/登山机)\n25分钟\n找到自己的训练强度并且记录下来",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-24T07:57:33.445Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139405663,
    "workout_id": 31579782,
    "name": "高位下拉",
    "info": "4组x12次，强度60%，节奏202，间歇60s\n手肘伸直，感受肩胛下沉，肋骨下沉",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-24T08:02:25.565Z",
    "attachments": [],
    "exercise_id": 626964,
    "request_video": false
  },
  {
    "id": 139405664,
    "workout_id": 31579782,
    "name": "蚌式 Clam",
    "info": "4组x12次，强度60%，节奏202，间歇60s\n保持肚脐正对前方，只有膝盖打开",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-24T08:02:25.568Z",
    "attachments": [],
    "exercise_id": 531176,
    "request_video": false
  },
  {
    "id": 139405665,
    "workout_id": 31579782,
    "name": "背伸展 Back Extension",
    "info": "3组x15次，节奏212，间歇90s\n大拇指向外旋，肩膀离开地面，感受肩后部收紧",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-24T08:02:25.572Z",
    "attachments": [],
    "exercise_id": 539816,
    "request_video": false
  },
  {
    "id": 139405666,
    "workout_id": 31579782,
    "name": "站姿髋外展Cable Hip Abduction",
    "info": "3组x15次，节奏212，间歇90s\n单脚站立，另一侧大腿外展",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-24T08:02:25.575Z",
    "attachments": [],
    "exercise_id": 537516,
    "request_video": false
  },
  {
    "id": 139405667,
    "workout_id": 31579782,
    "name": "平板支撑 Plank",
    "info": "3组x30s，间歇30s\n推开肩胛，收紧腹部，骨盆后倾",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-06-24T08:02:25.579Z",
    "attachments": [],
    "exercise_id": 525109,
    "request_video": false
  },
  {
    "id": 139405668,
    "workout_id": 31579782,
    "name": "卷腹 Sit up",
    "info": "3组x15次，间歇30s\n下背贴垫，肩胛离开地面，颈椎中立",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-06-24T08:02:25.583Z",
    "attachments": [],
    "exercise_id": 638527,
    "request_video": false
  },
  {
    "id": 139407772,
    "workout_id": 31580186,
    "name": "有氧",
    "info": "划船500m/跑步800m/共4轮循环\n强度60%\n记录阻力, 速度或坡度,完成的时间。",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-24T08:06:54.821Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139407773,
    "workout_id": 31580186,
    "name": "死虫 Dead Bug",
    "info": "推墙死虫式x20次/共4轮循环\n强度60%\n主动推墙并且下背贴地。",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-24T08:06:54.825Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 139407774,
    "workout_id": 31580186,
    "name": "平板支撑 Plank",
    "info": "平板支撑脚尖外点地x20次/共4轮循环\n强度60%\n保持肩膀下背及髋部稳定不晃动。",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-24T08:06:54.829Z",
    "attachments": [],
    "exercise_id": 525109,
    "request_video": false
  },
  {
    "id": 139795211,
    "workout_id": 31669136,
    "name": "罗马尼亚硬拉 Romania Deadlift",
    "info": "4组x10次，强度70%，节奏211，间歇60s\n维持躯干中立位，幅度不要过大",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:45.389Z",
    "attachments": [],
    "exercise_id": 497637,
    "request_video": false
  },
  {
    "id": 139795212,
    "workout_id": 31669136,
    "name": "器械坐姿划船 Seated Row",
    "info": "4组x10次，强度70%，节奏211，间歇60s\n肩胛骨积极下沉",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:45.394Z",
    "attachments": [],
    "exercise_id": 482510,
    "request_video": false
  },
  {
    "id": 139795213,
    "workout_id": 31669136,
    "name": "器械腿弯举 Leg Curl",
    "info": "3组x12次，强度60%，节奏211，间歇60s\n避免小腿过度发力",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:45.399Z",
    "attachments": [],
    "exercise_id": 534249,
    "request_video": false
  },
  {
    "id": 139795214,
    "workout_id": 31669136,
    "name": "哑铃二头弯举 DB Biceps Curl",
    "info": "3组x12次，强度60%，节奏211，间歇60s\n避免借力",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:45.405Z",
    "attachments": [],
    "exercise_id": 541347,
    "request_video": false
  },
  {
    "id": 139795215,
    "workout_id": 31669136,
    "name": "俯卧“A”字背伸展",
    "info": "3组x30s，间歇60s\n手掌朝下，后背积极收紧",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:45.411Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139795216,
    "workout_id": 31669136,
    "name": "俯卧“W”字背伸展",
    "info": "3组x30s，间歇60s\n拇指朝上，后背积极收紧",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:45.417Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139795221,
    "workout_id": 31669140,
    "name": "臀桥 Hip Press",
    "info": "4组x15次，节奏323，间歇60s\n在最高位时，臀部保持夹紧并保持2秒H",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.272Z",
    "attachments": [],
    "exercise_id": 528913,
    "request_video": false
  },
  {
    "id": 139795224,
    "workout_id": 31669140,
    "name": "俯身哑铃三头臂屈伸Bent DB Triceps Extension",
    "info": "4组x15次，轻重量，节奏212，间歇60s\n躯干平行地面，大臂夹紧躯干不动",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.278Z",
    "attachments": [],
    "exercise_id": 534375,
    "request_video": false
  },
  {
    "id": 139795226,
    "workout_id": 31669140,
    "name": "死虫 Dead Bug",
    "info": "4x20s，节奏202，间歇60s\n对侧手脚交替动作",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.283Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 139795228,
    "workout_id": 31669140,
    "name": "有氧训练",
    "info": "(跑/爬坡/椭圆机/划船机/登山机)\n30分钟\n保持上周的速度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.288Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139795219,
    "workout_id": 31669141,
    "name": "\"TLI\"肩部伸展",
    "info": "4组x12次，强度60%，节奏121，间歇60s\n注意肩胛下沉，TL手肘不要高于肩部，I字保持肋骨下沉",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.267Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139795223,
    "workout_id": 31669141,
    "name": "器械坐姿划船 Seated Row",
    "info": "4组x12次，强度60%，节奏202，间歇60s\n分解动作要做出来，切不可急躁，肩胛回收，手肘后拖",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.276Z",
    "attachments": [],
    "exercise_id": 482510,
    "request_video": false
  },
  {
    "id": 139795225,
    "workout_id": 31669141,
    "name": "俯身\"W\" to \"Y\"",
    "info": "3组x15次，节奏121，间歇90s\n注意肩部后方发力，肩胛骨的下回旋挤压",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.282Z",
    "attachments": [],
    "exercise_id": 626975,
    "request_video": false
  },
  {
    "id": 139795227,
    "workout_id": 31669141,
    "name": "背墙\"W\" to \"Y\"",
    "info": "3组x15次，节奏101，间歇90s\n尽可能让肩胛，肩，手肘，手腕手指贴近墙面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.287Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 139795229,
    "workout_id": 31669141,
    "name": "死虫 Dead Bug",
    "info": "3组x15，节奏202，间歇60s\n保持腰部贴实地面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.293Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 139795230,
    "workout_id": 31669141,
    "name": "背伸展 Back Extension",
    "info": "3组x15次，节奏212，间歇60s\n保持腰部稳定",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.298Z",
    "attachments": [],
    "exercise_id": 539816,
    "request_video": false
  },
  {
    "id": 139795218,
    "workout_id": 31669142,
    "name": "虫爬 Inch Worm",
    "info": "虫爬 - 1分钟/共5轮\n强度60%\n移动时身体稳定不摇晃, 双手保持在肩膀下方",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.275Z",
    "attachments": [],
    "exercise_id": 534355,
    "request_video": false
  },
  {
    "id": 139795220,
    "workout_id": 31669142,
    "name": "俯身\"W\" to \"Y\"",
    "info": "俯卧\"W\"to\"Y\"伸展 - 1分钟/共5轮\n强度0-2kg\n尽可能将背部及颈部保持延伸,避免耸肩或者塌腰",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.279Z",
    "attachments": [],
    "exercise_id": 626975,
    "request_video": false
  },
  {
    "id": 139795222,
    "workout_id": 31669142,
    "name": "卷腹 Sit up",
    "info": "卷腹手碰脚尖 - 1分钟/共5轮\n强度2-4kg\n用双脚或膝盖夹紧药球, 避免下背及臀部离开地面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-06-25T10:52:47.282Z",
    "attachments": [],
    "exercise_id": 638527,
    "request_video": false
  },
  {
    "id": 139798329,
    "workout_id": 31669142,
    "name": "休息",
    "info": "休息 -1分钟",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-06-25T11:07:25.546Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 141761161,
    "workout_id": 32111083,
    "name": "高脚杯下蹲 Goblet Squat",
    "info": "4组x8次，强度75%，节奏211，间歇90s\n坐到一个膝盖左右高度的板凳上然后站起\n躯干尽量维持竖直",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.200Z",
    "attachments": [],
    "exercise_id": 482503,
    "request_video": false
  },
  {
    "id": 141761162,
    "workout_id": 32111083,
    "name": "俯卧撑",
    "info": "4组x8次，强度75%，节奏211，间歇90s\n比上次做到更低的幅度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.213Z",
    "attachments": [],
    "exercise_id": 626976,
    "request_video": false
  },
  {
    "id": 141761163,
    "workout_id": 32111083,
    "name": "臀桥 Hip Press",
    "info": "3组x10次，强度70%，节奏211，间歇60s\n垫高脚跟，增加动作幅度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.220Z",
    "attachments": [],
    "exercise_id": 528913,
    "request_video": false
  },
  {
    "id": 141761164,
    "workout_id": 32111083,
    "name": "坐姿哑铃推肩 Shoulder Press",
    "info": "3组x10次，强度70%，节奏211，间歇60s\n手肘和躯干保持45度角",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.227Z",
    "attachments": [],
    "exercise_id": 541353,
    "request_video": false
  },
  {
    "id": 141761165,
    "workout_id": 32111083,
    "name": "死虫 Dead Bug",
    "info": "3组x10次 左/右，间歇60s\n腰部和背部都要紧贴地面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.232Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 141761166,
    "workout_id": 32111083,
    "name": "平板支撑 Plank",
    "info": "3组x40s，间歇60s\n骨盆积极后倾",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.253Z",
    "attachments": [],
    "exercise_id": 525109,
    "request_video": false
  },
  {
    "id": 141761167,
    "workout_id": 32111085,
    "name": "单脚臀桥 Single Leg Hip Press",
    "info": "3组x15次，左/右，节奏313，间歇60s\n骨盆转动向上, 并且在最高位保持伸展",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.975Z",
    "attachments": [],
    "exercise_id": 539556,
    "request_video": false
  },
  {
    "id": 141761168,
    "workout_id": 32111085,
    "name": "俯身哑铃三头臂屈伸Bent DB Triceps Extension",
    "info": "4组x12次，轻重量，节奏212，间歇60s\n重量比第一周稍重",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.979Z",
    "attachments": [],
    "exercise_id": 534375,
    "request_video": false
  },
  {
    "id": 141761169,
    "workout_id": 32111085,
    "name": "躯干保持 Hollow Body",
    "info": "4x30s，间歇60s\n手和脚的动作幅度比第一周大，同时腰要贴地",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.982Z",
    "attachments": [],
    "exercise_id": 532256,
    "request_video": false
  },
  {
    "id": 141761170,
    "workout_id": 32111085,
    "name": "有氧训练",
    "info": "(跑/爬坡/椭圆机/划船机/登山机)\n25分钟\n强度需要比第一周大，记录你的成绩",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:48.985Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 141761171,
    "workout_id": 32111087,
    "name": "移动平板支撑 Moving Plank",
    "info": "10次/共4轮循环\n强度60%\n动作过程避免身体摇晃及塌腰。",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.049Z",
    "attachments": [],
    "exercise_id": 548368,
    "request_video": false
  },
  {
    "id": 141761173,
    "workout_id": 32111087,
    "name": "侧平板抬臀",
    "info": "10次 左/右 /共4轮循环\n强度60%\n保持支撑手肘在肩膀下方,避免耸肩",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.053Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 141761175,
    "workout_id": 32111087,
    "name": "死虫 Dead Bug",
    "info": "20次/共4轮循环\n强度60%\n保持下背贴地, 膝盖可以微曲",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.057Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 141761172,
    "workout_id": 32111088,
    "name": "杠铃早安 Good Morning",
    "info": "4组x12次，强度60%，节奏201，间歇60s\n腰背伸直",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.050Z",
    "attachments": [],
    "exercise_id": 542492,
    "request_video": false
  },
  {
    "id": 141761174,
    "workout_id": 32111088,
    "name": "高位下拉",
    "info": "4组x12次，强度60%，节奏202，间歇60s\n分解动作要做出来，切不可急躁，肩胛下沉，手肘往身体靠近",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.054Z",
    "attachments": [],
    "exercise_id": 626964,
    "request_video": false
  },
  {
    "id": 141761176,
    "workout_id": 32111088,
    "name": "俯身\"W\" to \"Y\"",
    "info": "2组x15次，节奏121，间歇60s\n注意肩部后方发力，肩胛骨的下回旋挤压",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.059Z",
    "attachments": [],
    "exercise_id": 626975,
    "request_video": false
  },
  {
    "id": 141761177,
    "workout_id": 32111088,
    "name": "站姿髋外展Cable Hip Abduction",
    "info": "4组x15次，节奏212，间歇90s\n单脚站立，大腿外展",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.064Z",
    "attachments": [],
    "exercise_id": 537516,
    "request_video": false
  },
  {
    "id": 141761178,
    "workout_id": 32111088,
    "name": "躯干保持 Hollow Body",
    "info": "3组x30s，间歇30s\n腰部紧贴地面",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.070Z",
    "attachments": [],
    "exercise_id": 532256,
    "request_video": false
  },
  {
    "id": 141761179,
    "workout_id": 32111088,
    "name": "卷腹 Sit up",
    "info": "3组x15次，节奏111，间歇30s\n避免爆发力",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-07-01T09:24:49.073Z",
    "attachments": [],
    "exercise_id": 638527,
    "request_video": false
  },
  {
    "id": 149549717,
    "workout_id": 33870130,
    "name": "罗马尼亚硬拉 Romania Deadlift",
    "info": "4组x6次，强度80%，节奏211，间歇90s\n比上次下降到更低的幅度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.848Z",
    "attachments": [],
    "exercise_id": 497637,
    "request_video": false
  },
  {
    "id": 149549718,
    "workout_id": 33870130,
    "name": "器械坐姿划船 Seated Row",
    "info": "4组x6次，强度80%，节奏211，间歇90s\n肩胛骨和肋骨积极下沉",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.852Z",
    "attachments": [],
    "exercise_id": 482510,
    "request_video": false
  },
  {
    "id": 149549719,
    "workout_id": 33870130,
    "name": "器械腿弯举 Leg Curl",
    "info": "3组x15次，强度50%，节奏211，间歇60s\n避免小腿过度发力",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.856Z",
    "attachments": [],
    "exercise_id": 534249,
    "request_video": false
  },
  {
    "id": 149549722,
    "workout_id": 33870130,
    "name": "高位下拉",
    "info": "3组x15次，强度50%，节奏211，间歇60s\n用手肘带动发力",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.862Z",
    "attachments": [],
    "exercise_id": 626964,
    "request_video": false
  },
  {
    "id": 149549725,
    "workout_id": 33870130,
    "name": "俯卧“W”字背伸展",
    "info": "3组x30s，间歇60s\n轻负重",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.867Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549729,
    "workout_id": 33870130,
    "name": "侧平板支撑 Side Plank",
    "info": "3组x30s，左/右，间歇60s\n找到手肘向内收的感觉",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.874Z",
    "attachments": [],
    "exercise_id": 528867,
    "request_video": false
  },
  {
    "id": 149549720,
    "workout_id": 33870132,
    "name": "\"TLI\"肩部伸展",
    "info": "4组x12次，强度60%，节奏211，间歇60s\n注意肩胛下沉，TL手肘不要高于肩部，I字保持肋骨下沉",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.860Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549723,
    "workout_id": 33870132,
    "name": "器械坐姿划船 Seated Row",
    "info": "4组x12次，强度60%，节奏211，间歇60s\n分解动作要做出来，切不可急躁，肩胛回收，手肘后拖",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.865Z",
    "attachments": [],
    "exercise_id": 482510,
    "request_video": false
  },
  {
    "id": 149549726,
    "workout_id": 33870132,
    "name": "单臂弹力带肩外旋",
    "info": "2组x15次，左/右，节奏211，间歇30s\n大臂贴实躯干，分侧进行，弱侧优先",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.869Z",
    "attachments": [],
    "exercise_id": 600987,
    "request_video": false
  },
  {
    "id": 149549728,
    "workout_id": 33870132,
    "name": "单臂水平肩外旋",
    "info": "2组x15次，左/右，节奏211，间歇30s\n分侧进行，弱侧优先",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.874Z",
    "attachments": [],
    "exercise_id": 631297,
    "request_video": false
  },
  {
    "id": 149549731,
    "workout_id": 33870132,
    "name": "卷腹 Sit up",
    "info": "4组x15，节奏211，间歇30s\n减少爆发力",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 5,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.878Z",
    "attachments": [],
    "exercise_id": 638527,
    "request_video": false
  },
  {
    "id": 149549732,
    "workout_id": 33870132,
    "name": "坐姿提膝",
    "info": "4组x15次，节奏202，间歇30s\n保持腰部稳定，骨盆转动",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 6,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.882Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549721,
    "workout_id": 33870133,
    "name": "单脚臀桥 Single Leg Hip Press",
    "info": "4组x15次，左/右，节奏313，间歇60s\n骨盆转动向上, 并且在最高位保持伸展",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.861Z",
    "attachments": [],
    "exercise_id": 539556,
    "request_video": false
  },
  {
    "id": 149549724,
    "workout_id": 33870133,
    "name": "窄握俯卧撑 Narrow Push up",
    "info": "3组x10次，节奏212，间歇60s\n大拇指在胸下下方，下落时手肘夹紧身体",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.866Z",
    "attachments": [],
    "exercise_id": 538320,
    "request_video": false
  },
  {
    "id": 149549727,
    "workout_id": 33870133,
    "name": "死虫 Dead Bug",
    "info": "4x30s，节奏202，间歇60s\n手和脚的动作幅度比第一周大，同时腰要贴地",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.870Z",
    "attachments": [],
    "exercise_id": 528869,
    "request_video": false
  },
  {
    "id": 149549730,
    "workout_id": 33870133,
    "name": "有氧训练",
    "info": "(跑/爬坡/椭圆机/划船机/登山机)\n30分钟\n保持上周的速度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:55.875Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549733,
    "workout_id": 33870134,
    "name": "平板撑击球",
    "info": "平板撑击球 - 1分钟/共5轮\n强度60%\n要重重拍打药球, 注意身体不可旋转或改变高度",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 1,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:56.166Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549734,
    "workout_id": 33870134,
    "name": "交叉卷腹",
    "info": "交叉卷腹 - 1分钟/共5轮\n60%\n注意发力位置在腹部而不是脖子或肩膀",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 2,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:56.170Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549735,
    "workout_id": 33870134,
    "name": "划船/跑步",
    "info": "划船/跑步- 1分钟/共5轮\n60%\n记录使用的阻力, 速度或坡度, 完成的卡路里总数.",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": true,
    "position": 3,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:56.173Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  },
  {
    "id": 149549736,
    "workout_id": 33870134,
    "name": "休息",
    "info": "休息 -1分钟",
    "result": "",
    "is_circuit": false,
    "state": "pending",
    "selected_exercises": [],
    "linked": false,
    "position": 4,
    "assessment_id": null,
    "created_at": "2019-07-24T08:25:56.176Z",
    "attachments": [],
    "exercise_id": null,
    "request_video": false
  }
]

const warmups = [
  {
    "id": 41433,
    "description": "3 轮\n20 髋部旋转到伸展\n20 蝎子转身\n20 移动腿后侧拉伸",
    "name": "DLC #1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "562232",
        "name": "髋部旋转"
      },
      {
        "id": "562233",
        "name": "蝎子转身"
      },
      {
        "id": "562234",
        "name": "移动腿后侧拉伸"
      }
    ]
  },
  {
    "id": 41436,
    "description": "3轮\n20 蟹步走\n20 鸭步走\n10 反向髋伸展",
    "name": "DLC #2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "562239",
        "name": "蟹步走"
      },
      {
        "id": "562241",
        "name": "鸭步走"
      },
      {
        "id": "550764",
        "name": "反向髋伸展 Hip Extension"
      }
    ]
  },
  {
    "id": 41437,
    "description": "3轮\n10 跪姿髋伸展(每边)\n10 徒手过头蹲\n10 臀推保持(停2秒)",
    "name": "DLC #3",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "562243",
        "name": "跪姿髋伸展"
      },
      {
        "id": "497638",
        "name": "徒手过头蹲 Overhead Squat"
      },
      {
        "id": "562245",
        "name": "臀推保持"
      }
    ]
  },
  {
    "id": 38211,
    "description": "2 Rounds\nFollow numbers done then switch side\nRoller\n1. Tibialis Anterior 1 Min \n2. Calf (out side) 1 Min \nThen \n3. Downward Dog Calf Stretch 30 sec (Bent Knee, heels close floor don't touch) \n4. Butterfly inner stretch 30 sec\n5. Clam x 15\n6. Wall Squat x 10 (Facing Wall, try deeper reach rep)\nLeft and Right as 1 Rounds",
    "name": "Running Warm up for Nelson",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "535298",
        "name": "Tibialis Anterio"
      },
      {
        "id": "535299",
        "name": "Calf (out side)"
      },
      {
        "id": "535302",
        "name": "Downward Dog Calf Stretch"
      },
      {
        "id": "535303",
        "name": "Butterfly inner stretch"
      },
      {
        "id": "531176",
        "name": "蚌式 Clam"
      },
      {
        "id": "535304",
        "name": "Wall Squat"
      }
    ]
  },
  {
    "id": 54217,
    "description": "3組\n彈力帶夾背拉*15\n彈力帶面拉*15\n四足跪姿肩胛俯臥撐*15",
    "name": "上肢推熱身 1",
    "trainer_id": 48997,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "664302",
        "name": "彈力帶夾背拉"
      },
      {
        "id": "664308",
        "name": "彈力帶面拉 Band Face Pull"
      },
      {
        "id": "664328",
        "name": "四足跪姿肩胛俯臥撐"
      }
    ]
  },
  {
    "id": 54219,
    "description": "3組\n半程土耳其起立*5 (男12/女8公斤)\n肩胛引體向上*15\n熱燙馬鈴薯*15",
    "name": "上肢推熱身 2",
    "trainer_id": 48997,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "664244",
        "name": "半程土耳其起立 Half TUG"
      },
      {
        "id": "664275",
        "name": "肩胛引體向上 Shoulder Blade Pull-up"
      },
      {
        "id": "664295",
        "name": "熱'燙馬鈴薯 Hot Potato"
      }
    ]
  },
  {
    "id": 54218,
    "description": "3組\n半跪姿彈力帶單方向繞肩*5\n彈力帶拔劍*15\n壺鈴倒握5秒*5 (男12/女8公斤)",
    "name": "上肢推熱身 3",
    "trainer_id": 48997,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "658472",
        "name": "壺鈴倒握穩定"
      },
      {
        "id": "664311",
        "name": "彈力帶拔劍式"
      },
      {
        "id": "664326",
        "name": "弹力带半跪姿单向绕肩"
      }
    ]
  },
  {
    "id": 51253,
    "description": "胸背泡沫轴滚动\n肩部环绕\n肘部环绕\n手腕环绕\n3轮，每个动作20s",
    "name": "倒立解锁热身",
    "trainer_id": 43740,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "631361",
        "name": "胸背泡沫轴滚动"
      },
      {
        "id": "631654",
        "name": "肩部环绕"
      },
      {
        "id": "631676",
        "name": "肘部环绕"
      },
      {
        "id": "631696",
        "name": "手腕环绕"
      }
    ]
  },
  {
    "id": 55426,
    "description": "椭圆机\n10分钟\n让身体热起来",
    "name": "减脂营#1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": []
  },
  {
    "id": 55427,
    "description": "2轮\n10虫爬\n10 后侧步弓步(每边)\n30秒 躯干保持",
    "name": "减脂营#2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "534355",
        "name": "虫爬 Inch Worm"
      },
      {
        "id": "523732",
        "name": "后侧步弓步 Step Back Lunge"
      },
      {
        "id": "532256",
        "name": "躯干保持 Hollow Body"
      }
    ]
  },
  {
    "id": 38068,
    "description": "3轮\n10 伟大拉伸 Greatest World Stretch (每边5)\n10 虫爬 Inch Worm\n10 放松俯卧撑 Release Pushup",
    "name": "力量#1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "532850",
        "name": "伟大拉伸 Greatest World Stretching"
      },
      {
        "id": "534355",
        "name": "虫爬 Inch Worm"
      },
      {
        "id": "609003",
        "name": "放松俯卧撑 Release Pushup"
      }
    ]
  },
  {
    "id": 38069,
    "description": "3轮\n20 空蹲 Air Squat\n10 单脚鞠躬 Single Leg Hinge\n10米 鸭步走 Duck Walk",
    "name": "力量#2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "534368",
        "name": "空蹲 Air Squat"
      },
      {
        "id": "534369",
        "name": "单脚鞠躬 Single Leg Hinge"
      },
      {
        "id": "534370",
        "name": "鸭步走 Duck Walk"
      }
    ]
  },
  {
    "id": 52310,
    "description": "3轮\n10 肩胛骨俯卧撑\n20 行走腿后侧伸展 (交替)\n10 臀桥推",
    "name": "基础硬拉热身#1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "640591",
        "name": "肩胛骨俯卧撑"
      },
      {
        "id": "640593",
        "name": "行走腿后侧伸展"
      },
      {
        "id": "640596",
        "name": "臀桥推"
      }
    ]
  },
  {
    "id": 52369,
    "description": "3 轮\n6 伟大拉伸 (每边)\n20 左右弓步伸展 (交替)\n8 跪姿髋伸展 (每边)",
    "name": "基础硬拉热身#2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "641263",
        "name": "左右弓步伸展 "
      },
      {
        "id": "641264",
        "name": "跪姿髋伸展(3)"
      },
      {
        "id": "641261",
        "name": "伟大拉伸"
      }
    ]
  },
  {
    "id": 26885,
    "description": "2轮 (每轮增加幅度)\n10 跪姿胸椎转 （左/右）\n10 平板到下犬\n20 猎鸟狗 （交替）",
    "name": "大众热身 #1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "534082",
        "name": "跪姿胸椎转"
      },
      {
        "id": "534116",
        "name": "平板到下犬"
      },
      {
        "id": "532530",
        "name": "猎鸟狗"
      }
    ]
  },
  {
    "id": 26886,
    "description": "2轮 (每轮增加幅度)\n10 伟大拉伸（左/右）\n20 交替侧弓步伸展（交替）\n10 臀桥",
    "name": "大众热身 #2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "532850",
        "name": "伟大拉伸"
      },
      {
        "id": "532852",
        "name": "交替侧弓步伸展"
      },
      {
        "id": "528913",
        "name": "臀桥式"
      }
    ]
  },
  {
    "id": 50755,
    "description": "2轮\n背墙肩胛骨激活1 20次 \n背墙肩胛骨激活2 20次 \n板凳平板到下犬 15次",
    "name": "引体向上热身#1",
    "trainer_id": 43741,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "626986",
        "name": "背墙肩胛骨激活1"
      },
      {
        "id": "626989",
        "name": "背墙肩胛骨激活2"
      },
      {
        "id": "626990",
        "name": "板凳平板到下犬"
      }
    ]
  },
  {
    "id": 38058,
    "description": "3 轮\n20 移动腿伸展 Walk Hamstring Stretch\n20 髋转动 Hip Rotation\n30秒 开合跳 Jumping Jack",
    "name": "有氧#1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "556985",
        "name": "移动腿伸展 Walk Hamstring Stretch"
      },
      {
        "id": "534341",
        "name": "髋转动 Hip Rotation"
      },
      {
        "id": "534339",
        "name": "开合跳 Jumping Jack"
      }
    ]
  },
  {
    "id": 38065,
    "description": "2 轮\n1分钟 跪姿弓步伸展 Kneeling Hip Extension\n1分钟 正面摆腿 Leg Swing\n1分钟 侧面摆腿 Side Leg Swing\n10M 鸭步走 Duck Walk",
    "name": "有氧#2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "534326",
        "name": "跪姿弓步伸展"
      },
      {
        "id": "534328",
        "name": "正面摆腿"
      },
      {
        "id": "534329",
        "name": "侧面摆腿"
      },
      {
        "id": "534330",
        "name": "鸭步走"
      }
    ]
  },
  {
    "id": 42001,
    "description": "1 轮\n泡沫轴放松\n臀部中间  左/右 各1分钟\n大腿前侧  左/右 各1分钟\n小腿中段  左/右 各1分钟",
    "name": "核心模板#1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "565672",
        "name": "臀部中间"
      },
      {
        "id": "565673",
        "name": "大腿前侧"
      },
      {
        "id": "565678",
        "name": "小腿中段"
      }
    ]
  },
  {
    "id": 42002,
    "description": "1轮\n泡沫轴放松\t\n胸椎中段 1分钟\n大腿前侧 左/右 各1分钟\n猫式伸展 30次",
    "name": "核心模板#2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "565684",
        "name": "胸椎中段"
      },
      {
        "id": "565673",
        "name": "大腿前侧"
      },
      {
        "id": "565685",
        "name": "猫式伸展"
      }
    ]
  },
  {
    "id": 42003,
    "description": "1 轮\n滚轴放松\n臀部中间 左/右 1分钟\n大腿前侧 左/右 1分钟\n小腿中段 左/右 1分钟\n\n猫式伸展 30次",
    "name": "核心模板#3",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "565672",
        "name": "臀部中间"
      },
      {
        "id": "565673",
        "name": "大腿前侧"
      },
      {
        "id": "565678",
        "name": "小腿中段"
      },
      {
        "id": "565685",
        "name": "猫式伸展"
      }
    ]
  },
  {
    "id": 38074,
    "description": "滚轴\n1分钟 胸椎 T Spine\n1分钟 背部 Lat (每边) \n1分钟 臀部 Gluteus (每边) \n然后\n2轮\n10 跪姿胸椎转 Kneeling T Spine Rotation (每边) \n20 猎鸟狗 Hunter Bird Dog",
    "name": "激活#1上身",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "532493",
        "name": "滚轴-胸椎 Roller T Spine"
      },
      {
        "id": "532508",
        "name": "滚轴-背部 Roller Lat"
      },
      {
        "id": "532501",
        "name": "滚轴-臀部 Roller Mid Gluteus"
      },
      {
        "id": "534082",
        "name": "跪姿胸椎转 Kneeling T Spine Rotation"
      },
      {
        "id": "532530",
        "name": "猎鸟狗 Hunter Bird Dog"
      }
    ]
  },
  {
    "id": 38075,
    "description": "滚轴\n1分钟 臀部 Gluteus\n1分钟 大腿前 Quad\n1分钟 小腿 Calf\n然后\n2轮\n1分钟 交替侧弓步伸展 Cossack\n10 后侧步弓步到平衡 Reverse Lunge to Balance",
    "name": "激活#2下身",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "532501",
        "name": "滚轴-臀部 Roller Mid Gluteus"
      },
      {
        "id": "532489",
        "name": "滚轴-大腿 Roller Quad"
      },
      {
        "id": "532497",
        "name": "滚轴小腿 Roller Calf"
      },
      {
        "id": "532852",
        "name": "交替侧弓步伸展 Cossack"
      },
      {
        "id": "534394",
        "name": "后侧步弓步到平衡 Reverse Lunge to Balance"
      }
    ]
  },
  {
    "id": 44687,
    "description": "3 轮\n30 秒 抱膝 Knee Hug\n30 秒 4字拉腿 Leg Pull\n30秒 前后摆腿 Leg Swing\n30秒 反向弓步加转体 Reverse Lunge With Twist",
    "name": "跑步#1",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "587568",
        "name": "抱膝 Knee Hug"
      },
      {
        "id": "587569",
        "name": "4字拉腿 Leg Pull"
      },
      {
        "id": "587571",
        "name": "前后摆腿 Leg Swing"
      },
      {
        "id": "587572",
        "name": "反向弓步加转体 Reverse Lunge With Twist"
      }
    ]
  },
  {
    "id": 44875,
    "description": "3 轮\n10 伟大拉伸 Greatest World Stretch - 每边\n10 单脚硬拉 Single Leg Hinge - 每边\n20 移动腿伸展 Walk Hamstring Stretch - 交替",
    "name": "跑步#2",
    "trainer_id": 1,
    "organization_id": 1,
    "selected_exercises": [
      {
        "id": "532850",
        "name": "伟大拉伸 Greatest World Stretching"
      },
      {
        "id": "558107",
        "name": "单脚鞠躬 Single Leg Hinge"
      },
      {
        "id": "556985",
        "name": "移动腿伸展 Walk Hamstring Stretch"
      }
    ]
  }
]

const cooldowns = []

const exercises = [
  {
    "id": 764539,
    "default": false,
    "exercise_name": "TRX腿屈伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "绳索腿屈伸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/380adb05-7122-49d1-a3dd-2b666012f290/noroot.mp4",
        "type": "video/mp4",
        "size": 10480647
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 764517,
    "default": false,
    "exercise_name": "直臂下拉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "直臂下拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/67843a7d-5274-4bdb-b531-43ba4cca66d8/noroot.mp4",
        "type": "video/mp4",
        "size": 18617957
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 754042,
    "default": false,
    "exercise_name": "坐姿划船 轻健身",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "训练日A3—坐姿划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/899ad94c-6c90-4014-81dd-81cc0de73b99/A3.mp4",
        "type": "video/mp4",
        "size": 18464877
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 754024,
    "default": false,
    "exercise_name": "绳索肩外旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "绳索肩外旋.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/26933aca-b127-4a34-b8ea-cfeb4cbac6b0/noroot.mp4",
        "type": "video/mp4",
        "size": 8799882
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 592275,
    "default": false,
    "exercise_name": "垫上弓步蹲 2",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位腿部 难度L1 垫上箭步蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/bd523245-edac-4edd-8a7a-4fef2dfed3a8/L1.mp4",
        "type": "video/mp4",
        "size": 27862133
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 747857,
    "default": false,
    "exercise_name": "史密斯上斜卧推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位胸部 难度L3 上斜史密斯卧推.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9db796f9-92db-445b-a5da-8df574305be8/L3.mp4",
        "type": "video/mp4",
        "size": 54253615
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 747851,
    "default": false,
    "exercise_name": "横杆推胸（下胸）",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位下胸部 难度L1 俯身推胸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d10dda8a-79e5-436e-ba94-d759d8224a08/L1.mp4",
        "type": "video/mp4",
        "size": 69767372
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 747849,
    "default": false,
    "exercise_name": "绳索夹胸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位胸部中部 难度L3 绳索俯身夹胸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5cf68431-6fd5-4c5b-baf6-17720f62c561/L3.mp4",
        "type": "video/mp4",
        "size": 48860734
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 523742,
    "default": false,
    "exercise_name": "POW | 下蹲跳 Squat Jump",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Squat Jump.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6b7bfa3a-6b20-46c7-9998-25cde2d21839/SquatJump.mp4",
        "type": "video/mp4",
        "size": 1299025
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 601169,
    "default": false,
    "exercise_name": "MOB | 下转换 Under Switch",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "下转换under switch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/bb099703-bb17-4e96-b4c3-bd3d095fbc6f/underswitch.mp4",
        "type": "video/mp4",
        "size": 3722808
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539073,
    "default": false,
    "exercise_name": "STR | 上箱平衡 Step up to Balance",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "上步到平衡.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f4f025f7-d9ff-4912-b7f0-e729523897e0/noroot.mp4",
        "type": "video/mp4",
        "size": 2106043
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 587569,
    "default": false,
    "exercise_name": "MOB | 4字拉腿 Leg Pull",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Leg Pull.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3920815e-324d-4f6f-9919-06e1481729a9/LegPull.mp4",
        "type": "video/mp4",
        "size": 2039330
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 704678,
    "default": false,
    "exercise_name": "杠铃火箭推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位 腿部+肩部 杠铃火箭推 难度L5.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e6bbe574-cd35-4d27-865b-2e24f36bdfb5/L5.mp4",
        "type": "video/mp4",
        "size": 18836733
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 704664,
    "default": false,
    "exercise_name": "绳索挥劈",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "b61f72a0c73daefe36e44170aaf034cc.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5f5f4cf8-6ca9-4365-aca5-e29f7db0aa40/b61f72a0c73daefe36e44170aaf034cc.mp4",
        "type": "video/mp4",
        "size": 1410123
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 701705,
    "default": false,
    "exercise_name": "小腿前侧放松",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "小腿前侧Rolling Tibial.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6de135d4-d4ea-4f3d-ba17-572f246414be/RollingTibial.mp4",
        "type": "video/mp4",
        "size": 970738
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 697061,
    "default": false,
    "exercise_name": "股二头屈伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "训练日A4—股二头屈伸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/aa11f39a-65fd-4154-865d-fa49b1190984/A4.mp4",
        "type": "video/mp4",
        "size": 10480647
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 674796,
    "default": false,
    "exercise_name": "杠铃俯身划船 BB Bend over Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Barbell Bend Over Row.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c6dbbe5c-3943-4ad6-963b-23349b16b35f/BarbellBendOverRow.mp4",
        "type": "video/mp4",
        "size": 1882161
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 674646,
    "default": false,
    "exercise_name": "窄握平板卧推 Narrow Bench Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Narrow Chest Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0c54daf6-4b60-4a31-88e9-e07528291f48/NarrowChestPress.mp4",
        "type": "video/mp4",
        "size": 2938435
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664362,
    "default": false,
    "exercise_name": "架位休息農夫走",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "前架位农夫走.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/62ea3404-322d-4db4-9ed9-9459dc543410/noroot.mov",
        "type": "video/quicktime",
        "size": 19584513
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664352,
    "default": false,
    "exercise_name": "架位休息",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "前架位静止.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b4f85394-be95-46b3-b5b6-f83aac230db3/noroot.mov",
        "type": "video/quicktime",
        "size": 19635178
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664350,
    "default": false,
    "exercise_name": "階梯雙壺鈴借力挺",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "双壶铃借力挺.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/948d25f1-603a-4d3a-a08d-26433cecaa7c/noroot.mov",
        "type": "video/quicktime",
        "size": 19544059
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664344,
    "default": false,
    "exercise_name": "單壺鈴單腳羅馬尼亞硬拉",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "单脚罗马尼亚硬拉.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b62a95b4-b32a-47a1-98dc-63a2233720ea/noroot.mov",
        "type": "video/quicktime",
        "size": 19749079
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664333,
    "default": false,
    "exercise_name": "雙壺鈴架位深蹲",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "前架位深蹲.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5b65e141-b4be-43da-9470-8b4e152b02bc/noroot.mov",
        "type": "video/quicktime",
        "size": 19750467
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664328,
    "default": false,
    "exercise_name": "四足跪姿肩胛俯臥撐",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "四足跪姿肩胛俯卧撑.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0de37660-b978-4e98-af7a-15823055bc86/noroot.mov",
        "type": "video/quicktime",
        "size": 19313863
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664326,
    "default": false,
    "exercise_name": "弹力带半跪姿单向绕肩",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "弹力带半跪姿单向绕肩.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8bfa5bfe-eeb3-4926-81bd-706f88e207bb/noroot.mov",
        "type": "video/quicktime",
        "size": 19472104
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664311,
    "default": false,
    "exercise_name": "彈力帶拔劍式",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "弹力带拔剑式.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/34eb0cd5-f2ca-4f77-b38f-1bbd792cc69d/noroot.mov",
        "type": "video/quicktime",
        "size": 19453930
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664308,
    "default": false,
    "exercise_name": "彈力帶面拉 Band Face Pull",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "弹力带面拉.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d646e0e5-c2ee-4719-8d19-7cdf83d8e360/noroot.mov",
        "type": "video/quicktime",
        "size": 19622202
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664306,
    "default": false,
    "exercise_name": "藥球死蟲  Medicine Ball Dead Bug",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "药球死虫.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a0b9de14-3eee-4702-b4b3-0360abf3268f/noroot.mov",
        "type": "video/quicktime",
        "size": 19775278
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664302,
    "default": false,
    "exercise_name": "彈力帶夾背拉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "弹力带夹背拉.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f0bfef8b-91fa-4f5b-894c-020e9f92492e/noroot.mov",
        "type": "video/quicktime",
        "size": 19620249
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664300,
    "default": false,
    "exercise_name": "I-Y-W",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "斜板椅IYW.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2783d334-4997-4a8c-9d1e-a75bbf1ddc59/IYW.mov",
        "type": "video/quicktime",
        "size": 30320002
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664296,
    "default": false,
    "exercise_name": " 啞鈴俯身反向飛鳥 Dumbbell Reverse Fly",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "哑铃俯身反向飞鸟.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/10235dd8-5512-4326-a810-8e46176a368d/noroot.mov",
        "type": "video/quicktime",
        "size": 19664076
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664295,
    "default": false,
    "exercise_name": "熱'燙馬鈴薯 Hot Potato",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "热烫马铃薯.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/cef00f41-ae07-45f9-a80d-315267c1c9a9/noroot.mov",
        "type": "video/quicktime",
        "size": 19502224
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664293,
    "default": false,
    "exercise_name": "滑輪下拉 Lat Pull Down",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "高位下拉.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0d707c18-ea75-4b7b-a0bc-3c6593466b5f/noroot.mov",
        "type": "video/quicktime",
        "size": 19625513
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664292,
    "default": false,
    "exercise_name": "雙壺鈴前架位農夫走 Double Kettlebell Rack Walk",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "前架位农夫走.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a9fd5909-a20a-4971-9725-c7a62b950159/noroot.mov",
        "type": "video/quicktime",
        "size": 19584513
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664286,
    "default": false,
    "exercise_name": "雙壺鈴前架位深蹲 Double Kettlebell Rack Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "前架位深蹲.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/233c2c28-3ad5-4151-848f-1484ed0382cd/noroot.mov",
        "type": "video/quicktime",
        "size": 19750467
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664283,
    "default": false,
    "exercise_name": "雙壺鈴前架位 Double Kettlebell Rack",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "前架位静止.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9ae29d72-415b-401c-9326-2b7a875e75aa/noroot.mov",
        "type": "video/quicktime",
        "size": 19635178
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664275,
    "default": false,
    "exercise_name": "肩胛引體向上 Shoulder Blade Pull-up",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "肩胛引体向上.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/89546a55-9789-49fc-9a38-6cce835c9fc3/noroot.mov",
        "type": "video/quicktime",
        "size": 19588806
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664270,
    "default": false,
    "exercise_name": "壺鈴單腳羅馬尼亞硬拉 Single Leg RDL",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "单脚罗马尼亚硬拉.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6623939d-d8fc-4fb5-b58c-c5b02f78fdb9/noroot.mov",
        "type": "video/quicktime",
        "size": 19749079
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664260,
    "default": false,
    "exercise_name": "坐姿反手划船  Sitted Reverse Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "坐姿反手划船.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f0701992-3154-4e72-bb8a-2429832167af/noroot.mov",
        "type": "video/quicktime",
        "size": 19643235
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [
        "Back",
        "Rear Deltoid"
      ],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664251,
    "default": false,
    "exercise_name": "平板支橕單手划船 Plank One Arm Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "平板支撑单手划船.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4c710671-a759-4e73-8182-6c8c6cf804c4/noroot.mov",
        "type": "video/quicktime",
        "size": 19738228
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [
        "Warm up"
      ],
      "primary_muscles": [],
      "plane": [
        "Saggital"
      ],
      "pattern": [
        "Stability"
      ]
    }
  },
  {
    "id": 664248,
    "default": false,
    "exercise_name": "四足跪姿肩胛俯臥橕 Shoulder Blade Push-up",
    "description": "Be careful to stable the neck and low back while moving upper back.",
    "url": "",
    "attachments": [
      {
        "name": "四足跪姿肩胛俯卧撑.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e4140028-401a-435a-bea5-294244b9483c/noroot.mov",
        "type": "video/quicktime",
        "size": 19313863
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [
        "Warm up"
      ],
      "primary_muscles": [],
      "plane": [],
      "pattern": [
        "Stability"
      ]
    }
  },
  {
    "id": 664244,
    "default": false,
    "exercise_name": "半程土耳其起立 Half TUG",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "半程土耳其起立.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/85c5c72b-3c47-4bd1-bd37-49a74e4c33af/noroot.mov",
        "type": "video/quicktime",
        "size": 65972152
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 664218,
    "default": false,
    "exercise_name": "土耳其起立  TGU",
    "description": "Keep pushing the kettlebell to the ceiling.",
    "url": null,
    "attachments": [
      {
        "name": "土耳其起立.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f47af4ec-c641-4155-8465-d7239d16c618/noroot.mov",
        "type": "video/quicktime",
        "size": 65700403
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [
        "Warm up"
      ],
      "primary_muscles": [],
      "plane": [],
      "pattern": [
        "Stability"
      ]
    }
  },
  {
    "id": 662598,
    "default": false,
    "exercise_name": "哑铃火箭推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "y2mate.com - dumbbell_thrusters_lQRngGRZSq0_144p.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6c181dbd-9c2f-48f6-929b-45c49c41d7ba/y2matecomdumbbell_thrusters_lQRngGRZSq0_144p.mp4",
        "type": "video/mp4",
        "size": 1155668
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 658472,
    "default": false,
    "exercise_name": "壺鈴倒握穩定",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "壶铃倒握稳定.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ebac0428-18be-45fd-ae37-c26144bd649a/noroot.mov",
        "type": "video/quicktime",
        "size": 19435423
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [
        "Saggital"
      ],
      "pattern": [
        "Stability"
      ]
    }
  },
  {
    "id": 658470,
    "default": false,
    "exercise_name": "雙壺鈴借力挺",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "双壶铃借力挺.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/eb685347-a4aa-4665-b715-7028272707df/noroot.mov",
        "type": "video/quicktime",
        "size": 19544059
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 658466,
    "default": false,
    "exercise_name": "雙壺鈴借力推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "双壶铃借力推.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fb0d9a89-54c4-4d63-848c-418218433d15/noroot.mov",
        "type": "video/quicktime",
        "size": 19486092
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 658465,
    "default": false,
    "exercise_name": "單壺鈴軍式推舉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "单壶铃军推.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/19e3c9a3-c8ba-47a4-b77c-af59c98ad73e/noroot.mov",
        "type": "video/quicktime",
        "size": 19533849
      }
    ],
    "trainer_id": 48997,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 645361,
    "default": false,
    "exercise_name": "悬垂抬膝 Hanging Knee Raise",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Hanging Knee Raise.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a695e357-a006-4c12-bb6d-435b0180cb1d/HangingKneeRaise.mp4",
        "type": "video/mp4",
        "size": 2232278
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 644214,
    "default": false,
    "exercise_name": "asdfadsj",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Air Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/7e965ff2-a1af-42b2-b334-bc7532909bae/AirSquat.mp4",
        "type": "video/mp4",
        "size": 2672054
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 642899,
    "default": false,
    "exercise_name": "TRX W 飞鸟",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX W FLymp4.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f642603a-5ce2-4091-a700-68319a043d48/TRXWFLymp4.mp4",
        "type": "video/mp4",
        "size": 1329991
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 642745,
    "default": false,
    "exercise_name": "杠铃前蹲(大众) Front Squat ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "FS21.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0ca8a3bc-a4ba-4cf3-a826-31fe94c17fbe/FS21.mp4",
        "type": "video/mp4",
        "size": 2868734
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 642702,
    "default": false,
    "exercise_name": "4字臀拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "站姿4字伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0a5f7631-8bb1-4a24-ad8f-b77bc75adf08/4.mp4",
        "type": "video/mp4",
        "size": 1783784
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641245,
    "default": false,
    "exercise_name": "腿升降",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "仰卧腿升降.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0a4248a6-4593-42ad-9277-1569749ae944/noroot.mp4",
        "type": "video/mp4",
        "size": 3134416
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641269,
    "default": false,
    "exercise_name": "跪姿旋转髋伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "1080p.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/92478bdf-e285-4529-8d0d-99a3b696973c/1080p.mp4",
        "type": "video/mp4",
        "size": 1807653
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641266,
    "default": false,
    "exercise_name": "下背部伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "下背部伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/bf7a254c-c6c9-4f5c-ad82-4bff98b15011/noroot.mp4",
        "type": "video/mp4",
        "size": 1801471
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641261,
    "default": false,
    "exercise_name": "伟大拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "伟大拉伸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9211e8fc-7328-4858-8dcb-6998dd6275c5/noroot.mp4",
        "type": "video/mp4",
        "size": 4163117
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641264,
    "default": false,
    "exercise_name": "跪姿髋伸展(3)",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "跪姿髋伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8175f807-f4a0-46ce-b681-d33ec4311eb1/noroot.mp4",
        "type": "video/mp4",
        "size": 1796325
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641263,
    "default": false,
    "exercise_name": "左右弓步伸展 ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "左右弓步.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/77f9171f-b298-4525-8ee7-e92b7092a9d1/noroot.mp4",
        "type": "video/mp4",
        "size": 1788169
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641251,
    "default": false,
    "exercise_name": "哑铃俯身划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "哑铃俯身划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c80c7712-df97-498e-8e39-8b6b8d97b73f/noroot.mp4",
        "type": "video/mp4",
        "size": 2751600
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 641246,
    "default": false,
    "exercise_name": "罗马尼亚硬拉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "罗马尼亚硬拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5083e246-7d7e-4674-b9de-e16468faf80e/noroot.mp4",
        "type": "video/mp4",
        "size": 2467703
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640626,
    "default": false,
    "exercise_name": "跪姿髋伸展2",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "跪姿髋伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6ce99f08-e0ff-4fd4-ac76-6f3f77ae1698/noroot.mp4",
        "type": "video/mp4",
        "size": 1796325
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640625,
    "default": false,
    "exercise_name": "眼镜蛇伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "眼镜蛇伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/cc17856f-c533-47db-a50f-251e4f32859f/noroot.mp4",
        "type": "video/mp4",
        "size": 1776906
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640607,
    "default": false,
    "exercise_name": "猎鸟狗",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "猎鸟狗.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/293efa84-0485-46b3-9c1a-5bc561e37824/noroot.mp4",
        "type": "video/mp4",
        "size": 1791475
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640606,
    "default": false,
    "exercise_name": "俯身股二头弯举",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "器械股二头弯举.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9b465094-7c5d-4783-b868-d2d48239bc82/noroot.mp4",
        "type": "video/mp4",
        "size": 1763107
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640603,
    "default": false,
    "exercise_name": "侧卧髋外展",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "侧卧髋外展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/47a20f21-302a-4140-8851-5a58dcb5790d/noroot.mp4",
        "type": "video/mp4",
        "size": 2183937
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640601,
    "default": false,
    "exercise_name": "坐姿划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "坐姿划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3dd015f7-b634-4aba-b562-784e4f9dd949/noroot.mp4",
        "type": "video/mp4",
        "size": 1814588
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640599,
    "default": false,
    "exercise_name": "杠铃早安",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "早安.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b8a54931-e340-4290-af14-2d210a0bfdc8/noroot.mp4",
        "type": "video/mp4",
        "size": 2092753
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640596,
    "default": false,
    "exercise_name": "臀桥推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "臀桥.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/837f0e0f-0dff-4970-82a1-827983246cbc/noroot.mp4",
        "type": "video/mp4",
        "size": 1784911
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640593,
    "default": false,
    "exercise_name": "行走腿后侧伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "行走腿后侧拉伸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/79f0ec43-d242-4de2-8e34-b6c0dce5a839/noroot.mp4",
        "type": "video/mp4",
        "size": 1781562
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 640591,
    "default": false,
    "exercise_name": "肩胛骨俯卧撑",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "肩胛骨俯卧撑.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2d3ecea2-e21b-4067-a367-ec850e19d6ac/noroot.mp4",
        "type": "video/mp4",
        "size": 1790069
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 638527,
    "default": false,
    "exercise_name": "卷腹 Sit up",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "47卷腹.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2f2ef25b-57a0-4a37-862d-8c4049475f91/47.mp4",
        "type": "video/mp4",
        "size": 2918826
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 638284,
    "default": false,
    "exercise_name": "坐姿上斜卧推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位上胸部 难度L1上斜坐姿推胸  .mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8b7f44d7-3e42-4f28-812c-024d0fc937e5/L1.mp4",
        "type": "video/mp4",
        "size": 35692111
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 638280,
    "default": false,
    "exercise_name": "史密斯平板卧推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位胸部 难度L3 史密斯卧推.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/44f89c5d-ebd7-44ca-912e-55a6a384cdb4/L3.mp4",
        "type": "video/mp4",
        "size": 114319564
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 638276,
    "default": false,
    "exercise_name": "绳索大腿屈伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位腿部后侧 绳索腿屈伸 难度L2.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/32507984-ef74-4572-9496-752a7ad88531/L2.mp4",
        "type": "video/mp4",
        "size": 47070339
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 637813,
    "default": false,
    "exercise_name": "器械臂屈伸 Tricep Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "LF Tricep Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ff72ec19-31e1-40a0-bc2c-884ec0cce0df/LFTricepPress.mp4",
        "type": "video/mp4",
        "size": 1664434
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 633278,
    "default": false,
    "exercise_name": "实力推举 ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv4实力肩推.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/98c38e33-0b19-4e74-9356-58b3ea3ab223/lv4.mp4",
        "type": "video/mp4",
        "size": 1774832
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631696,
    "default": false,
    "exercise_name": "手腕环绕",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "手腕环绕.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/beb6e51e-34e7-46d4-b60f-b4507c023f1a/noroot.mov",
        "type": "video/quicktime",
        "size": 19553840
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631676,
    "default": false,
    "exercise_name": "肘部环绕",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "肘部环绕.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/611f00d5-509a-481f-8002-4dd734dd51f1/noroot.mov",
        "type": "video/quicktime",
        "size": 19513210
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631654,
    "default": false,
    "exercise_name": "肩部环绕",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "肩部环绕.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/7e523676-0c3b-45f2-bd8c-4beacd25badf/noroot.mov",
        "type": "video/quicktime",
        "size": 19635131
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631361,
    "default": false,
    "exercise_name": "胸背泡沫轴滚动",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "胸背泡沫轴滚动.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5e5a8c7f-714c-4f3b-b841-c709572381cc/noroot.mov",
        "type": "video/quicktime",
        "size": 19521003
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631343,
    "default": false,
    "exercise_name": "尝试无墙控倒",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "尝试无墙倒立.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fbf40e1f-be07-4ef0-a6ed-7a4de792fbf0/noroot.mov",
        "type": "video/quicktime",
        "size": 26943699
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631342,
    "default": false,
    "exercise_name": "背墙倒立保持",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "背墙倒立保持.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6560609b-72b5-450a-8105-61ac17b0ab44/noroot.mov",
        "type": "video/quicktime",
        "size": 19449422
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631340,
    "default": false,
    "exercise_name": "背墙倒立上法",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "背墙倒立上法.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4709cb20-924d-4ca6-a89f-ea8820a2bd3f/noroot.mov",
        "type": "video/quicktime",
        "size": 39961062
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631338,
    "default": false,
    "exercise_name": "面墙倒立下法",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "面墙倒立下法.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9115cf5c-bdf9-4da8-89d6-cfce5525621c/noroot.mov",
        "type": "video/quicktime",
        "size": 27415540
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631329,
    "default": false,
    "exercise_name": "面墙倒立保持",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "面墙倒立保持.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/50f445f7-44c1-4602-be35-522996ccc221/noroot.mov",
        "type": "video/quicktime",
        "size": 19549757
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631323,
    "default": false,
    "exercise_name": "折刀支撑保持",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "折刀撑保持.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ad347848-e24e-45dc-be18-2a8fe56f04f6/noroot.mov",
        "type": "video/quicktime",
        "size": 19595950
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631313,
    "default": false,
    "exercise_name": "哑铃坐姿推举",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "坐姿哑铃推举.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d8419acb-6c85-433c-946c-d2d62876dd72/noroot.mov",
        "type": "video/quicktime",
        "size": 19602822
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631311,
    "default": false,
    "exercise_name": "折刀撑",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "折刀撑.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5d540f21-2db6-49d2-b9db-237bb3802535/noroot.mov",
        "type": "video/quicktime",
        "size": 19628287
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631308,
    "default": false,
    "exercise_name": "下斜俯卧撑",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "下斜俯卧撑.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3e885a3b-828f-4ce4-873c-8ea66714e5f8/noroot.mov",
        "type": "video/quicktime",
        "size": 19684050
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631303,
    "default": false,
    "exercise_name": "单臂肩外旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "单臂肩外旋.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b0a9121c-ce9c-4aaf-b9cb-b93359c36536/noroot.mov",
        "type": "video/quicktime",
        "size": 19863345
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631297,
    "default": false,
    "exercise_name": "单臂水平肩外旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "水平肩外旋.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8c537490-05ac-4d37-8ae8-f83ebecfa43e/noroot.mov",
        "type": "video/quicktime",
        "size": 19832271
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 631003,
    "default": false,
    "exercise_name": "船 式",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "船式.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/26945124-de70-4dfc-97fd-c1edcbe60da8/noroot.mov",
        "type": "video/quicktime",
        "size": 19633875
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 630990,
    "default": false,
    "exercise_name": "平板式",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "平板.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a351d667-96b1-4e28-93a0-9568001f2aa9/noroot.mov",
        "type": "video/quicktime",
        "size": 19687020
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 630961,
    "default": false,
    "exercise_name": "站姿壶铃推举保持",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "站姿壶铃推举保持.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3ff77e96-7612-4db0-977a-97d8297e3e4e/noroot.mov",
        "type": "video/quicktime",
        "size": 19608621
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 630945,
    "default": false,
    "exercise_name": "站姿哑铃推举",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "站姿哑铃推举.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fcde2c87-cd8b-4aca-a4fe-5b1f563a8a1a/noroot.mov",
        "type": "video/quicktime",
        "size": 19568271
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 627002,
    "default": false,
    "exercise_name": "跪姿板凳胸伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "跪姿旋转.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/aaa1acf0-4555-42c7-b325-cfb3c12a97b8/noroot.mov",
        "type": "video/quicktime",
        "size": 19504036
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 627000,
    "default": false,
    "exercise_name": "跪姿旋转伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "板凳平板到下犬式.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/97a21cea-54dd-4557-b5d9-11198e5e395d/noroot.mov",
        "type": "video/quicktime",
        "size": 19464589
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626994,
    "default": false,
    "exercise_name": "跪姿背伸展",
    "description": "",
    "url": null,
    "attachments": [
      {
        "name": "跪姿背伸展.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/69ebb6f8-04b1-4c9f-823b-89ad8686bfe9/noroot.mov",
        "type": "video/quicktime",
        "size": 19530152
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626990,
    "default": false,
    "exercise_name": "板凳平板到下犬",
    "description": "1. 双手放于平凳上成板式\n2. 肩胛启动用力前推变成下犬式\n3. 再次肩胛启动用力后拉再次回到板式",
    "url": null,
    "attachments": [
      {
        "name": "板凳平板到下犬式.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/bf257a37-f713-4663-b225-fa326421e29b/noroot.mov",
        "type": "video/quicktime",
        "size": 19464589
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [
        "Warm up"
      ],
      "primary_muscles": [],
      "plane": [
        "Saggital"
      ],
      "pattern": []
    }
  },
  {
    "id": 626989,
    "default": false,
    "exercise_name": "背墙肩胛骨激活2",
    "description": "1. 背部紧贴墙壁\n2. 双手握拳用力伸向地板\n3. 将双手向前抬起",
    "url": null,
    "attachments": [
      {
        "name": "背墙肩胛骨激活2.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/23c5b92c-31f5-4575-9073-a4d72e358813/2.mov",
        "type": "video/quicktime",
        "size": 19586629
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [
        "Warm up"
      ],
      "primary_muscles": [
        "Scapulars"
      ],
      "plane": [
        "Saggital"
      ],
      "pattern": []
    }
  },
  {
    "id": 626986,
    "default": false,
    "exercise_name": "背墙肩胛骨激活1",
    "description": "",
    "url": "",
    "attachments": [
      {
        "name": "背墙肩胛骨激活1.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d89ca2be-d6b0-455b-aaef-cb9e039f9d87/1.mov",
        "type": "video/quicktime",
        "size": 19600235
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [
        "Warm up"
      ],
      "primary_muscles": [],
      "plane": [
        "Frontal"
      ],
      "pattern": []
    }
  },
  {
    "id": 626984,
    "default": false,
    "exercise_name": "杠上肩部转换",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "杠上肩部转换.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a623664e-51fe-488a-9ab9-db04caca6643/noroot.mov",
        "type": "video/quicktime",
        "size": 19773462
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626983,
    "default": false,
    "exercise_name": "杠上肩部转换",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "杠上肩部转换.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2c0e93ec-4d30-4347-98a4-34d5ea02e4c5/noroot.mov",
        "type": "video/quicktime",
        "size": 19773462
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626982,
    "default": false,
    "exercise_name": "宽握反向划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "宽握反向划船.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ad12d51a-9fbc-4c00-89d0-3f4d25389922/noroot.mov",
        "type": "video/quicktime",
        "size": 19817010
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626980,
    "default": false,
    "exercise_name": "离心引体向上（反握）",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "离心引体（反握）.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/12f50dab-7bb5-42ff-a440-f9d36efb5066/noroot.mov",
        "type": "video/quicktime",
        "size": 19726032
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626979,
    "default": false,
    "exercise_name": "杠上肩部摆荡",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "杠上肩部摆荡.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fa05096e-ebb6-4675-8679-52226b2fcf93/noroot.mov",
        "type": "video/quicktime",
        "size": 19763912
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626978,
    "default": false,
    "exercise_name": "倒退爬行",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "倒退爬行.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/7604ac8c-e95d-4c17-9281-8ef096f302e6/noroot.mov",
        "type": "video/quicktime",
        "size": 25247844
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626976,
    "default": false,
    "exercise_name": "俯卧撑",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "俯卧撑.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fb62a898-d939-48c1-be1e-307884ddade1/noroot.mov",
        "type": "video/quicktime",
        "size": 19613025
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626975,
    "default": false,
    "exercise_name": "俯身\"W\" to \"Y\"",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "俯身“W”to“Y”.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a88e7138-bcc1-4dd0-8ecd-d50cf18e7c01/WtoY.mov",
        "type": "video/quicktime",
        "size": 19551829
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626974,
    "default": false,
    "exercise_name": "跪姿哑铃肩推",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "跪姿哑铃肩推.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/839f5660-04ec-4dd3-a61c-a23f2f8f3c1f/noroot.mov",
        "type": "video/quicktime",
        "size": 19510151
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626973,
    "default": false,
    "exercise_name": "反向二头弯举",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "反向二头弯举.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/488a7107-d92f-49fc-a001-11840121bdab/noroot.mov",
        "type": "video/quicktime",
        "size": 19824125
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626971,
    "default": false,
    "exercise_name": "直臂下压",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "直臂下压.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/23b32aa8-d13f-4a63-9e8a-b33cd58b46c5/noroot.mov",
        "type": "video/quicktime",
        "size": 20184836
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626969,
    "default": false,
    "exercise_name": "直臂下压",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "直臂下压.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b4960635-a2ec-49a0-b7ab-2cbcd0257d59/noroot.mov",
        "type": "video/quicktime",
        "size": 20184836
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626967,
    "default": false,
    "exercise_name": "站姿引体向上",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "站姿引体向上.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a56bdfab-d6f7-494d-867e-d5034bf85852/noroot.mov",
        "type": "video/quicktime",
        "size": 19575541
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626964,
    "default": false,
    "exercise_name": "高位下拉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "高位下拉.mov",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/15db0de5-b9b7-47b4-9a07-717a7144bbe2/noroot.mov",
        "type": "video/quicktime",
        "size": 19801925
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 626701,
    "default": false,
    "exercise_name": "前跳 Broad Jump",
    "description": null,
    "url": "https://www.youtube.com/watch?v=jf1XpLwZifk",
    "attachments": [],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 617982,
    "default": false,
    "exercise_name": "史密斯仰卧划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "史密斯仰卧划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/7558d488-1365-4d70-be1e-2a40a421ce20/noroot.mp4",
        "type": "video/mp4",
        "size": 22948549
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 616488,
    "default": false,
    "exercise_name": "半跪姿上旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "半跪姿上旋（对侧）.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/64711773-7942-4a1c-aba6-81aca6d87a4d/noroot.mp4",
        "type": "video/mp4",
        "size": 6574540
      }
    ],
    "trainer_id": 43741,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606434,
    "default": false,
    "exercise_name": "卷腹 2",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "47卷腹.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/7edfc99a-359b-4fa3-804a-e7afbd335476/47.mp4",
        "type": "video/mp4",
        "size": 2918826
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 614585,
    "default": false,
    "exercise_name": "背部拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "WeChat_20190417002133.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/13a62d08-ce61-4f63-995c-79de57940633/WeChat_20190417002133.mp4",
        "type": "video/mp4",
        "size": 1631664
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 614583,
    "default": false,
    "exercise_name": "大腿前侧拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "WeChat_20190417002109.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b1e3f6e0-a9ef-40f5-9c47-c2e2ee2acb68/WeChat_20190417002109.mp4",
        "type": "video/mp4",
        "size": 2219210
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 614578,
    "default": false,
    "exercise_name": "悬吊举腿",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv3悬吊举腿.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b959da84-41ba-46ff-8494-9cb9525e93fb/lv3.mp4",
        "type": "video/mp4",
        "size": 2230613
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 614577,
    "default": false,
    "exercise_name": "卷腹",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv1卷腹.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/abfe3171-4507-43d1-bffd-4de6f761eb6d/lv1.mp4",
        "type": "video/mp4",
        "size": 2918826
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 614568,
    "default": false,
    "exercise_name": "前弓步拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv2前弓步伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4240e897-ae9d-4b19-81c3-c85d9f89e072/lv2.mp4",
        "type": "video/mp4",
        "size": 1222960
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 613040,
    "default": false,
    "exercise_name": "绳索高抬腿",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位腿部 绳索高抬腿 难度L2.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e45e9d96-7543-408a-871c-6247567b2b61/L2.mp4",
        "type": "video/mp4",
        "size": 17045287
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612630,
    "default": false,
    "exercise_name": "平板哑铃三头推 Bench Triceps Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB Bench Triceps Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b360222e-00bc-414c-bad8-9a218e1a7474/DBBenchTricepsPress.mp4",
        "type": "video/mp4",
        "size": 2681522
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612398,
    "default": false,
    "exercise_name": "跪姿转体 Cable Kneeling Rotation",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Cable Kneeling Rotation.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/882fc3db-3047-4d96-a339-8d4780b27a9e/CableKneelingRotation.mp4",
        "type": "video/mp4",
        "size": 1767177
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612388,
    "default": false,
    "exercise_name": "TRX 臀推 Hip Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Hip Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/405e5c9a-65c5-41d8-a91b-3baf62c1702e/TRXHipPress.mp4",
        "type": "video/mp4",
        "size": 1353080
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612350,
    "default": false,
    "exercise_name": "侧向弓步平衡 Side Lunge Balance",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Side Lunge Balance.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c5e6e995-8b49-41b4-ad43-14c3a0878e07/SideLungeBalance.mp4",
        "type": "video/mp4",
        "size": 2028438
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612345,
    "default": false,
    "exercise_name": "器械股二头下压 Hamstring Curl (Down)",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Hamstring Curl(Down).mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/deaa33f8-db1d-4928-84a3-c0460f157fa8/HamstringCurlDown.mp4",
        "type": "video/mp4",
        "size": 2110829
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612337,
    "default": false,
    "exercise_name": "TRX 锯式 Body Saw",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX 锯式 Body Saw.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/41b5b549-b2bf-418a-b07f-0f29a0176e0f/TRXBodySaw.mp4",
        "type": "video/mp4",
        "size": 1289979
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612333,
    "default": false,
    "exercise_name": "侧向弓步 Side Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Barbell Side Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a72367ec-31f4-4ec3-b021-89535f5f7eec/BarbellSideLunge.mp4",
        "type": "video/mp4",
        "size": 1173824
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612301,
    "default": false,
    "exercise_name": "站姿绳索胸外展(飞鸟) Cable Chest Fly",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Cable Chest Fly.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1ba5b37f-dfa9-42b6-80bd-82ad1ced0a41/CableChestFly.mp4",
        "type": "video/mp4",
        "size": 1363823
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612261,
    "default": false,
    "exercise_name": "杠铃上斜板推胸 Incline Bench Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Incline Bench Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/21e7a8e4-e2fa-425f-b9b6-d95b1380c9c2/InclineBenchPress.mp4",
        "type": "video/mp4",
        "size": 1801818
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 612250,
    "default": false,
    "exercise_name": "TRX 悬挂弓步 Suspended Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX 悬挂弓步 Suspended Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3e9cc6f6-beb5-4a0b-b3df-ae63278e21a0/TRXSuspendedLunge.mp4",
        "type": "video/mp4",
        "size": 1635516
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 609052,
    "default": false,
    "exercise_name": "竹竿绕肩",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv3木杆绕圈 Shoulder Circles.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6af938f3-e052-46fc-8bba-ae5cac943908/lv3ShoulderCircles.mp4",
        "type": "video/mp4",
        "size": 1329788
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 609032,
    "default": false,
    "exercise_name": "坐姿后卷动 Sitting Roll Down",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Sitting Roll Down.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c5f427e2-9910-4ade-85d8-5d77048512be/SittingRollDown.mp4",
        "type": "video/mp4",
        "size": 1419672
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 609025,
    "default": false,
    "exercise_name": "跪姿肩上拉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Kneeling Shoulder Flexion.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/51b6fe01-a2b8-48f2-99f1-46fbff9c1e8d/KneelingShoulderFlexion.mp4",
        "type": "video/mp4",
        "size": 977862
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 609003,
    "default": false,
    "exercise_name": "放松俯卧撑 Release Pushup",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Release Pushup.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/aae963e1-30cd-4cb2-afc7-245348c9bcf1/ReleasePushup.mp4",
        "type": "video/mp4",
        "size": 1690001
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606449,
    "default": false,
    "exercise_name": "面墙提拉",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "面墙拉腿 Walk Leg Pull.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9d201370-6046-4144-baa6-d0e243e4f4eb/WalkLegPull.mp4",
        "type": "video/mp4",
        "size": 1907293
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606436,
    "default": false,
    "exercise_name": "滚轴—小腿前侧",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "小腿前侧Rolling Tibial.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/69a6ca59-1538-490f-9503-822e299c7cf1/RollingTibial.mp4",
        "type": "video/mp4",
        "size": 970738
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606435,
    "default": false,
    "exercise_name": "侧卷腹",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "39侧卷腹.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/26d8bc2d-05ce-48d9-ae0a-77f68bff4b8a/39.mp4",
        "type": "video/mp4",
        "size": 2293544
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606433,
    "default": false,
    "exercise_name": "绳索抗旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv2站姿腹前推.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/56e8a5c1-733a-4120-8540-babf9d67c8a0/lv2.mp4",
        "type": "video/mp4",
        "size": 1431059
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606432,
    "default": false,
    "exercise_name": "平板转体",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv1平板转体 Plank Rotate.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c4bdd20e-c9d3-45bf-a756-15daf493c4da/lv1PlankRotate.mp4",
        "type": "video/mp4",
        "size": 3493282
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606431,
    "default": false,
    "exercise_name": "跪姿旋转",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv3弓步伐木.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a81b0892-72de-4360-87e4-171be1a30d5c/lv3.mp4",
        "type": "video/mp4",
        "size": 1612621
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606430,
    "default": false,
    "exercise_name": "俯身单边划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv3单臂俯身划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0fef0375-19d3-41c4-93e3-695a64bac2e3/lv3.mp4",
        "type": "video/mp4",
        "size": 1645557
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606429,
    "default": false,
    "exercise_name": "俯身划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv2俯身划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b84a1819-8249-41de-a973-c9dca30538be/lv2.mp4",
        "type": "video/mp4",
        "size": 2019179
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606428,
    "default": false,
    "exercise_name": "trx跑步者",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX 股二头跑步者TRX Hamstring Runner.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/233d83a3-f006-4682-90df-e9ea0443e6e0/TRXTRXHamstringRunner.mp4",
        "type": "video/mp4",
        "size": 1145167
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606427,
    "default": false,
    "exercise_name": "跪姿髋拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv2跪姿髋伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f3c64db1-bedf-470a-99ca-6250223d48f3/lv2.mp4",
        "type": "video/mp4",
        "size": 1651348
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606426,
    "default": false,
    "exercise_name": "坐姿分叉前屈",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv2坐姿分腿体前屈.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b368bbf6-b392-4af6-9873-fb2b9554268e/lv2.mp4",
        "type": "video/mp4",
        "size": 1538385
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606425,
    "default": false,
    "exercise_name": "胸大肌拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv1站姿胸伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5d02f923-a45b-4bfe-a894-f24cd1ac39f4/lv1.mp4",
        "type": "video/mp4",
        "size": 1195121
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 606424,
    "default": false,
    "exercise_name": "木杆绕肩",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lv3木杆绕圈 Shoulder Circles.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/55131ba2-4cee-4fe9-98bf-13d988b31d3c/lv3ShoulderCircles.mp4",
        "type": "video/mp4",
        "size": 1329788
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 601172,
    "default": false,
    "exercise_name": "TRX 反向划船 Reverse Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX 反向划船Reverse Row.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/27d3db89-e035-486d-9145-6bfad9eddecc/TRXReverseRow.mp4",
        "type": "video/mp4",
        "size": 1947761
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 600988,
    "default": false,
    "exercise_name": "站姿弹力带水平肩外旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "水平肩外旋.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1019c775-612a-4765-8651-5b730a7d0578/noroot.mp4",
        "type": "video/mp4",
        "size": 555459
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 600987,
    "default": false,
    "exercise_name": "单臂弹力带肩外旋",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "单臂肩外旋.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3cdee356-9a67-468d-a009-14db1426a9ed/noroot.mp4",
        "type": "video/mp4",
        "size": 473983
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 600985,
    "default": false,
    "exercise_name": "三头绳索下压",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "三头绳索下压.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3f8e2820-761d-41fc-ac9a-9190a2907c7b/noroot.mp4",
        "type": "video/mp4",
        "size": 885644
      }
    ],
    "trainer_id": 43740,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 597368,
    "default": false,
    "exercise_name": "下蹲静力对抗 Squat  Press Hold",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Rip Drive Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/dfefff89-3af4-4c2d-be15-77ebd858bfd8/RipDriveSquat.mp4",
        "type": "video/mp4",
        "size": 491948
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 597364,
    "default": false,
    "exercise_name": "弹力带股二头弯举",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Band Hamstring Curl.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/44d189b5-b324-40ed-9045-d51564014d94/BandHamstringCurl.mp4",
        "type": "video/mp4",
        "size": 1391551
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 597357,
    "default": false,
    "exercise_name": "TRX Y 飞鸟 Fly",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Y 飞鸟 Fly.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/59523b09-057e-476c-98e1-f1a627624c30/TRXYFly.mp4",
        "type": "video/mp4",
        "size": 1433387
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 597355,
    "default": false,
    "exercise_name": "TRX 平板 支撑 Plank",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX 平板支撑 Plank.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8adb03de-320c-479a-bb05-6479a8618e03/TRXPlank.mp4",
        "type": "video/mp4",
        "size": 956631
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 593435,
    "default": false,
    "exercise_name": "爬行 Crawl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "70爬行（前）.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/716d1f88-fcce-4673-b06a-b69df780ec24/70.mp4",
        "type": "video/mp4",
        "size": 3021317
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 592298,
    "default": false,
    "exercise_name": "面墙抬腿",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "面墙拉腿 Walk Leg Pull.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3875cdcb-f2bb-45c5-a7ca-d8094bd984d8/WalkLegPull.mp4",
        "type": "video/mp4",
        "size": 1907293
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 592297,
    "default": false,
    "exercise_name": "左右侧蹲",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "32左右侧蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d0b5c461-91f9-4ff5-a3af-08f11ad3f6ee/32.mp4",
        "type": "video/mp4",
        "size": 1546325
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 592294,
    "default": false,
    "exercise_name": "哑铃原地弓步",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "哑铃原地弓步 DB Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c4acaf20-b26e-4850-9a0f-31d44eecde14/DBLunge.mp4",
        "type": "video/mp4",
        "size": 2253877
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 592081,
    "default": false,
    "exercise_name": "杠铃半蹲 Half Back Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Half Back Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4e7de293-2ecf-4532-b991-1cd8f4b79fcb/HalfBackSquat.mp4",
        "type": "video/mp4",
        "size": 1310065
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 590369,
    "default": false,
    "exercise_name": "杠铃过头蹲 Overhead Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "BBOHS.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/61608264-d38a-4282-863c-1e6a48f28dea/BBOHS.mp4",
        "type": "video/mp4",
        "size": 1924642
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 590367,
    "default": false,
    "exercise_name": "TRX 下蹲跳Squat Jump",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Squat Jump.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c3928e50-0e57-4782-aa0d-6feb1d7804ea/TRXSquatJump.mp4",
        "type": "video/mp4",
        "size": 789751
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 590365,
    "default": false,
    "exercise_name": "敏捷梯进出 Ladder in \u0026 out ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Ladder in\u0026out.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3962532a-8adb-41f9-98c0-ec5c03bd51f2/Ladderinout.mp4",
        "type": "video/mp4",
        "size": 2272452
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 590360,
    "default": false,
    "exercise_name": "面墙拉腿 Wall Leg Pull",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "面墙拉腿.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d42ca7ab-b7a1-4f60-87ca-777335382694/noroot.mp4",
        "type": "video/mp4",
        "size": 1907293
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 588666,
    "default": false,
    "exercise_name": "绳索高抬腿",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位腿部 绳索高抬腿 难度L2.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/dbbd23d6-2c59-46be-8300-45cb0a1ba9ca/L2.mp4",
        "type": "video/mp4",
        "size": 17045287
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 588651,
    "default": false,
    "exercise_name": "俯身单臂划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位背部 难度L3 俯身单臂划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c23c35c3-2816-44bf-bc43-54914bde1716/L3.mp4",
        "type": "video/mp4",
        "size": 33950750
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 588603,
    "default": false,
    "exercise_name": "俯身杠铃划船",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "部位背部 难度L4 俯身杠铃划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/38866ce7-4341-42de-a938-2d18d9d4db3f/L4.mp4",
        "type": "video/mp4",
        "size": 42641074
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 587572,
    "default": false,
    "exercise_name": "反向弓步加转体 Reverse Lunge With Twist",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Reverse Lunge with twist.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/af2a3393-b85a-4709-a5b1-b9f2bb48ee23/ReverseLungewithtwist.mp4",
        "type": "video/mp4",
        "size": 3432992
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 587571,
    "default": false,
    "exercise_name": "前后摆腿 Leg Swing",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Leg Swing.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d8705f57-569b-420d-b0d4-b381f7af43ca/LegSwing.mp4",
        "type": "video/mp4",
        "size": 934982
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 587568,
    "default": false,
    "exercise_name": "抱膝 Knee Hug",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Knee Hug.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0ead9cba-5102-4377-9ae4-8fa50cb3f83d/KneeHug.mp4",
        "type": "video/mp4",
        "size": 1753226
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 587043,
    "default": false,
    "exercise_name": "Half Back Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Half Back Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/849d3665-e8ca-47f6-81cd-560242cc5abe/HalfBackSquat.mp4",
        "type": "video/mp4",
        "size": 1310065
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 586573,
    "default": false,
    "exercise_name": "器械钢线下压 Cable Rope Triceps Press Down",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Cable Rope Tri Ext.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ebdfc8db-1af4-4f9f-8b69-a0db97a1ea09/CableRopeTriExt.mp4",
        "type": "video/mp4",
        "size": 1467358
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 583812,
    "default": false,
    "exercise_name": "四足支撑",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "VID_20190327_151921.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0ba70449-77a7-4b31-b8c4-db85aff49afb/VID_20190327_151921.mp4",
        "type": "video/mp4",
        "size": 10564744
      }
    ],
    "trainer_id": 1,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 579225,
    "default": false,
    "exercise_name": "反向划船 ",
    "description": null,
    "url": "https://www.youtube.com/watch?v=5LZa9TzkjRg",
    "attachments": [],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 579209,
    "default": false,
    "exercise_name": "窄握胸推",
    "description": null,
    "url": "https://www.youtube.com/watch?v=U0nVKVnTbEA",
    "attachments": [],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 574132,
    "default": false,
    "exercise_name": "哑铃上斜板推举 DB Bench Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB incline CP.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/575c36d3-aef0-4aa7-99c9-6bbc8b4b88fd/DBinclineCP.mp4",
        "type": "video/mp4",
        "size": 2646528
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 570137,
    "default": false,
    "exercise_name": "哑铃上斜板推胸 DB Incline Chest Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB incline CP.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/613d2e12-db15-487e-8c8a-16becc94a55a/DBinclineCP.mp4",
        "type": "video/mp4",
        "size": 2646528
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 570134,
    "default": false,
    "exercise_name": "膝盖俯卧撑 Kneeling Push up",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Kneelng Push up.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f99942c2-81f4-47e3-a387-2299a2e95d56/KneelngPushup.mp4",
        "type": "video/mp4",
        "size": 2059169
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 570133,
    "default": false,
    "exercise_name": "站姿面拉 Face Pull",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Face Pull.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/070dc897-e8fd-4cd8-917f-521b34450c58/FacePull.mp4",
        "type": "video/mp4",
        "size": 3622698
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565925,
    "default": false,
    "exercise_name": "绳索外展 Cable Hip Abduction",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Cable Hip Abduction.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6da02367-f1c8-45dd-8106-d3594be94960/CableHipAbduction.mp4",
        "type": "video/mp4",
        "size": 1506825
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565891,
    "default": false,
    "exercise_name": "TRX 单腿髋曲 Single Leg Hinge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Single Leg Hinge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b232efbb-6690-4851-ab15-a71252d34e28/TRXSingleLegHinge.mp4",
        "type": "video/mp4",
        "size": 725511
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565889,
    "default": false,
    "exercise_name": "TRX 跑步者 Runner",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Hamstring Runner.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e09a8565-2e97-4fbd-9afe-e17c4678669b/TRXHamstringRunner.mp4",
        "type": "video/mp4",
        "size": 1145167
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565870,
    "default": false,
    "exercise_name": "徒手下蹲 Air Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Air Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ae7fa98f-fd9d-4575-8c8e-2dbbc6886c5d/AirSquat.mp4",
        "type": "video/mp4",
        "size": 2672054
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565850,
    "default": false,
    "exercise_name": "放松俯卧撑 ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Release Pushup.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0aa38e6f-c8e6-4e38-af96-fe250e16fbcf/ReleasePushup.mp4",
        "type": "video/mp4",
        "size": 1690001
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565847,
    "default": false,
    "exercise_name": "死虫 Dead Bug (同侧)",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Dead Bug Same side.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0ef54849-9c0d-47d8-a2d4-2e1f092d21af/DeadBugSameside.mp4",
        "type": "video/mp4",
        "size": 653120
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565844,
    "default": false,
    "exercise_name": "杠铃后侧步弓步蹲 BB Step Back Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "BB Step Back Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/543f5535-4103-4e19-acd5-50e921e11796/BBStepBackLunge.mp4",
        "type": "video/mp4",
        "size": 971952
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565707,
    "default": false,
    "exercise_name": "单腿硬拉 Single Leg Deadlift",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "SLDL.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/81161933-7073-49e0-a6d8-15ac744ae4c2/SLDL.mp4",
        "type": "video/mp4",
        "size": 1930141
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565696,
    "default": false,
    "exercise_name": "臀推 Glute Thrust",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Gluteus Thurst.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3ac9010a-f2ac-4628-858c-9b8ea21849bb/GluteusThurst.mp4",
        "type": "video/mp4",
        "size": 1402958
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565693,
    "default": false,
    "exercise_name": "臀推 ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Gluteus Thurst.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6213de35-00c3-429d-8e9c-faa09c214e0d/GluteusThurst.mp4",
        "type": "video/mp4",
        "size": 1402958
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565685,
    "default": false,
    "exercise_name": "猫式伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "07猫式伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a820e030-7464-4cf6-9e41-34f0b07a417f/07.mp4",
        "type": "video/mp4",
        "size": 1831443
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565684,
    "default": false,
    "exercise_name": "胸椎中段",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "25泡沫轴胸椎放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ee4c74c8-06de-4678-9ad5-94b99faadfc8/25.mp4",
        "type": "video/mp4",
        "size": 2113459
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565678,
    "default": false,
    "exercise_name": "小腿中段",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "27泡沫轴小腿三头肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a10b1838-3d28-477a-a20e-f9a1c40d10c2/27.mp4",
        "type": "video/mp4",
        "size": 2229625
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565673,
    "default": false,
    "exercise_name": "大腿前侧",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "26泡沫轴股四头肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/428ac1da-a76c-4904-a7b9-fb7313f31da9/26.mp4",
        "type": "video/mp4",
        "size": 2328010
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 565672,
    "default": false,
    "exercise_name": "臀部中间",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "29泡沫轴臀肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ee0368cb-782e-4a19-b23e-81e5dfd7b608/29.mp4",
        "type": "video/mp4",
        "size": 1516807
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 564807,
    "default": false,
    "exercise_name": "按摩球 三头",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "lat Massage.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6a955f89-3e08-4120-b87e-128329bbe3f9/latMassage.mp4",
        "type": "video/mp4",
        "size": 2368776
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 564802,
    "default": false,
    "exercise_name": "Eccentric DB Wrist Curl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "wrist curl.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/75b11cfa-2445-4e5a-b6e8-48afe6ce44e7/wristcurl.mp4",
        "type": "video/mp4",
        "size": 1039735
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 564794,
    "default": false,
    "exercise_name": "支架俯卧撑Parallette push ups",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "PPush up.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4e7743db-8fb2-49ae-a5fe-25a242cd1bf9/PPushup.mp4",
        "type": "video/mp4",
        "size": 1447649
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 564785,
    "default": false,
    "exercise_name": "锤式弯举 Hammer Curl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Hammer Curl.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/eb3a1066-8ac7-479a-a8b2-861fb3b8388f/HammerCurl.mp4",
        "type": "video/mp4",
        "size": 1203158
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 564147,
    "default": false,
    "exercise_name": "蟹爬 Crab Walk",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Crab Walk.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ddc1296a-b871-4eb2-981e-9fab0e2c4e37/CrabWalk.mp4",
        "type": "video/mp4",
        "size": 1386384
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562245,
    "default": false,
    "exercise_name": "臀推保持",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "12臀桥.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/10bb1a01-8037-41e3-958f-7bb3217519ee/12.mp4",
        "type": "video/mp4",
        "size": 1830316
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562243,
    "default": false,
    "exercise_name": "跪姿髋伸展",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "05前弓步伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/bb94c50c-bd83-4b23-abe1-725c0c834c0e/05.mp4",
        "type": "video/mp4",
        "size": 1222960
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562241,
    "default": false,
    "exercise_name": "鸭步走",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "duck walk.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0ed2b4de-66ae-4913-b244-678eb6510f64/duckwalk.mp4",
        "type": "video/mp4",
        "size": 3035552
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562239,
    "default": false,
    "exercise_name": "蟹步走",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Crab Walk.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a2937f92-6332-4a03-859f-dc18e35b0a92/CrabWalk.mp4",
        "type": "video/mp4",
        "size": 1386384
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562234,
    "default": false,
    "exercise_name": "移动腿后侧拉伸",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "walk hamstring stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2e48b70b-b6de-48ad-88bc-d30686696c3c/walkhamstringstretch.mp4",
        "type": "video/mp4",
        "size": 2410963
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562233,
    "default": false,
    "exercise_name": "蝎子转身",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Scopion Stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/590b04eb-169c-42fa-8162-7112ce85ea50/ScopionStretch.mp4",
        "type": "video/mp4",
        "size": 2696641
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 562232,
    "default": false,
    "exercise_name": "髋部旋转",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Hip Rotate.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/91ae8377-79a4-47df-afa8-229472799044/HipRotate.mp4",
        "type": "video/mp4",
        "size": 1985980
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 560563,
    "default": false,
    "exercise_name": "TRX 交叉弓步蹲 Crossing Balncing Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Crossing Balance Lunge1.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ded2f601-89d7-4868-b953-11b2c1604daf/TRXCrossingBalanceLunge1.mp4",
        "type": "video/mp4",
        "size": 1060684
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 560186,
    "default": false,
    "exercise_name": "宽握高位下拉 Wide Lat Pulldown",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Wide Lat Pulldown.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f2cc5ca6-7d0b-4095-ad20-243672b718b0/WideLatPulldown.mp4",
        "type": "video/mp4",
        "size": 2417884
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 551288,
    "default": false,
    "exercise_name": "TRX 滚动向前Roll out",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "TRX Roll out.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1246049d-a9c8-48c5-9ce1-f69593024999/TRXRollout.mp4",
        "type": "video/mp4",
        "size": 2507139
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 558107,
    "default": false,
    "exercise_name": "单脚鞠躬 Single Leg Hinge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "34移动单腿体前屈.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/88328591-035e-4396-92f0-0fc39ac09816/34.mp4",
        "type": "video/mp4",
        "size": 2045307
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 557466,
    "default": false,
    "exercise_name": "平板旋转Plank Rotate",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "plank rotate.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/db8e037e-7a40-422c-ae0a-2416bacd520d/plankrotate.mp4",
        "type": "video/mp4",
        "size": 3493282
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 556985,
    "default": false,
    "exercise_name": "移动腿伸展 Walk Hamstring Stretch",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "walk hamstring stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3416b62d-7c17-439c-83c9-f66456bb22ec/walkhamstringstretch.mp4",
        "type": "video/mp4",
        "size": 2410963
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 556982,
    "default": false,
    "exercise_name": "移动腿伸展Walk Hamstring Stretch",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "walk hamstring stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/bf177ed2-640f-4645-9732-ccf3137a78b5/walkhamstringstretch.mp4",
        "type": "video/mp4",
        "size": 2410963
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 556274,
    "default": false,
    "exercise_name": "蝎子髋伸展 Scorpion",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Scopion Stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/47c77929-e294-4fc7-a545-2a4e45dfc94f/ScopionStretch.mp4",
        "type": "video/mp4",
        "size": 2696641
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 555033,
    "default": false,
    "exercise_name": "TRX 中位划船 Mid Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Mid Row.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2e438218-2dce-4a17-b011-68b02b4cec76/TRXMidRow.mp4",
        "type": "video/mp4",
        "size": 1301188
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 555024,
    "default": false,
    "exercise_name": "TRX 股二头弯举到髋推 Hamstring Curl to Hip Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Hamstring Curl to Hip Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/befda871-d265-4351-bde3-3e7c15316a4b/TRXHamstringCurltoHipPress.mp4",
        "type": "video/mp4",
        "size": 719640
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 553846,
    "default": false,
    "exercise_name": "TRX 平衡弓步蹲 Balance Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Balance Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/17c3ee1b-f3b1-4fdd-93f5-9b41916e916b/TRXBalanceLunge.mp4",
        "type": "video/mp4",
        "size": 1377996
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 482503,
    "default": false,
    "exercise_name": "高脚杯下蹲 Goblet Squat",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Godlet Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1cd527fe-fe0d-4d91-972c-89528c4112d5/GodletSquat.mp4",
        "type": "video/mp4",
        "size": 1777940
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 546898,
    "default": false,
    "exercise_name": "平板哑铃胸推 DB Bench Chest Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB Bench Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4a6d0e9f-fe12-4439-a656-2e572c87fcaf/DBBenchPress.mp4",
        "type": "video/mp4",
        "size": 686930
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534276,
    "default": false,
    "exercise_name": "侧向爬行 Lateral Crawl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "71爬行（左右）.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e0551c26-18da-4e08-ac11-e7773e98dc27/71.mp4",
        "type": "video/mp4",
        "size": 3140971
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 550820,
    "default": false,
    "exercise_name": "TRX 三头推 Triceps Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Chest Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/346f7377-2e75-4167-a5bf-3c97ebc54c1c/TRXChestPress.mp4",
        "type": "video/mp4",
        "size": 1356859
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 550764,
    "default": false,
    "exercise_name": "反向髋伸展 Hip Extension",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "反向伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/10a95eaa-ed57-4be3-af43-5a71cf56f841/noroot.mp4",
        "type": "video/mp4",
        "size": 1974823
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 548368,
    "default": false,
    "exercise_name": "移动平板支撑 Moving Plank",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Move Plank1.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fa9c8ea4-731b-496b-8e39-e8a5479cd30b/MovePlank1.mp4",
        "type": "video/mp4",
        "size": 567203
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 546883,
    "default": false,
    "exercise_name": "站姿正面肩外旋 Facing Shoulder External Rotation",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "shoulder external rotation1.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b8c4a573-e679-47c7-9a87-83f7c922bee9/shoulderexternalrotation1.mp4",
        "type": "video/mp4",
        "size": 866404
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 544919,
    "default": false,
    "exercise_name": "交替髋伸展 Alternate Hip Extension",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "俯身髋伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fc867004-893f-46f6-9242-3075a969c952/noroot.mp4",
        "type": "video/mp4",
        "size": 831894
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534328,
    "default": false,
    "exercise_name": "正面摆腿 Leg Swing",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Leg Swing.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/72f37cf0-1032-4265-a589-bd4c774071db/LegSwing.mp4",
        "type": "video/mp4",
        "size": 934982
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534318,
    "default": false,
    "exercise_name": "移动股二头伸展 Walk Hamstring Stretch",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "walk hamstring stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1c87cf3d-04fb-4103-a795-5aa79a6027af/walkhamstringstretch.mp4",
        "type": "video/mp4",
        "size": 2410963
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 546893,
    "default": false,
    "exercise_name": "TRX 二头弯举 Biceps Curl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Biceps Curl.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6bc20403-514e-455e-a4fc-9b5cbb4bd681/TRXBicepsCurl.mp4",
        "type": "video/mp4",
        "size": 1554884
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 546892,
    "default": false,
    "exercise_name": "登山者 Mountain Climber",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Mountain Climber.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a4e1f6e2-dfa8-4600-8b7e-f1858c6ee0f6/MountainClimber.mp4",
        "type": "video/mp4",
        "size": 841486
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 545832,
    "default": false,
    "exercise_name": "靠墙火箭推",
    "description": null,
    "url": "https://www.youtube.com/watch?v=Yoo7jgmG9KA",
    "attachments": [],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 545825,
    "default": false,
    "exercise_name": "器械站姿髋伸展 Hip Extension Cable",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "HIip extesnion cable.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8a427268-9f84-4462-b94c-fcc163ab788c/HIipextesnioncable.mp4",
        "type": "video/mp4",
        "size": 291220
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 545763,
    "default": false,
    "exercise_name": "相扑硬拉 Sumo Deadlift",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "73相扑硬拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/adc0d66a-6e5d-428e-ba41-ee6e064fca03/73.mp4",
        "type": "video/mp4",
        "size": 2351795
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 545762,
    "default": false,
    "exercise_name": "器械坐姿推肩 Seated Shoulder Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Seated shoulder Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/78b4153d-48e7-4afa-9ee8-b706987ec52b/SeatedshoulderPress.mp4",
        "type": "video/mp4",
        "size": 2995046
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 543797,
    "default": false,
    "exercise_name": "TRX 推胸 Chest Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Chest Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c797a39b-8cf3-46a4-9f78-76be12ffed90/TRXChestPress.mp4",
        "type": "video/mp4",
        "size": 1356859
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 542994,
    "default": false,
    "exercise_name": "TRX 低位划船 Low Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Low Row.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9603bf99-462b-4d6d-b61b-9be35d3d45e5/TRXLowRow.mp4",
        "type": "video/mp4",
        "size": 1326566
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 542494,
    "default": false,
    "exercise_name": "TRX 股二头弯举 Hamstring Curl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "TRX Hamtring Curl.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/28946e6a-ae31-4889-b2e0-63c0e41bd404/TRXHamtringCurl.mp4",
        "type": "video/mp4",
        "size": 1060994
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 542492,
    "default": false,
    "exercise_name": "杠铃早安 Good Morning",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Good Morning.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/585f9c80-f6d3-4646-93bb-7f16b470078b/GoodMorning.mp4",
        "type": "video/mp4",
        "size": 2093577
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541727,
    "default": false,
    "exercise_name": "单脚曲髋 Single Leg Hinge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "34移动单腿体前屈.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/7b5f8153-1575-4959-b18f-a809a4ea98a1/34.mp4",
        "type": "video/mp4",
        "size": 2045307
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541683,
    "default": false,
    "exercise_name": "髋转动+髋伸展 Hip Rotation to Hip Extension",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Hip Rotate.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/cdefbff9-5e85-4828-8cc0-965162fcb598/HipRotate.mp4",
        "type": "video/mp4",
        "size": 1985980
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541649,
    "default": false,
    "exercise_name": "保加利亚剪蹲 Bulgarian Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "40保加利亚剪蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ba27a71b-ee74-40fc-9593-163b6aaabe36/40.mp4",
        "type": "video/mp4",
        "size": 2060047
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541630,
    "default": false,
    "exercise_name": "直腿硬拉 Stiff Leg Deadlift",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "72直腿硬拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/904e7af6-c5df-428a-83e3-400288b77c21/72.mp4",
        "type": "video/mp4",
        "size": 2240613
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541353,
    "default": false,
    "exercise_name": "坐姿哑铃推肩 Shoulder Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB Shoulder Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d595e0c6-46f1-4332-81c6-3757635cd2ab/DBShoulderPress.mp4",
        "type": "video/mp4",
        "size": 1502127
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541347,
    "default": false,
    "exercise_name": "哑铃二头弯举 DB Biceps Curl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "53哑铃二头弯举.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/df67ae4f-e6fa-44c1-90d3-03ea5d99f763/53.mp4",
        "type": "video/mp4",
        "size": 1188612
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 541340,
    "default": false,
    "exercise_name": "哑铃下蹲 Dumbbell Front Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "48双哑铃前蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ded65fcd-6c33-4e72-8029-0360df7a1435/48.mp4",
        "type": "video/mp4",
        "size": 1764196
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539825,
    "default": false,
    "exercise_name": "箱子背蹲 Box Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "79箱式背蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fa3d8261-5c96-4dd6-aa19-9b2f6d9419e6/79.mp4",
        "type": "video/mp4",
        "size": 1611294
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539816,
    "default": false,
    "exercise_name": "背伸展 Back Extension",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "14背伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8d263706-74b6-4de0-9cd9-41f79384e123/14.mp4",
        "type": "video/mp4",
        "size": 2147429
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539814,
    "default": false,
    "exercise_name": "哑铃后侧步弓步蹲 DB Step Back Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Step Back Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/db898a7a-6f66-4d6a-a545-104b51b80cc3/StepBackLunge.mp4",
        "type": "video/mp4",
        "size": 1435019
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539643,
    "default": false,
    "exercise_name": "哑铃单臂划船 Single Arm DB Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "46单臂俯身划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b3ac857f-9754-439b-9e40-96f449b63c39/46.mp4",
        "type": "video/mp4",
        "size": 1645557
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539633,
    "default": false,
    "exercise_name": "哑铃俯身划船 Bent Over Row",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Dumbbell Bent OVer Row.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d0d764c5-74f9-4934-9d17-c40eab2c0e03/DumbbellBentOVerRow.mp4",
        "type": "video/mp4",
        "size": 1346837
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539626,
    "default": false,
    "exercise_name": "蝎子转体 Scorpion",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Scopion Stretch.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ca0ef2fa-8e55-4068-82e0-41ec984adcb2/ScopionStretch.mp4",
        "type": "video/mp4",
        "size": 2696641
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539623,
    "default": false,
    "exercise_name": "器械坐姿推胸 Seated Chest Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Seated chest press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c808f457-1eee-477a-888d-19a4999bbb0f/Seatedchestpress.mp4",
        "type": "video/mp4",
        "size": 3180276
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539597,
    "default": false,
    "exercise_name": "杠铃弓步蹲 Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Reverse Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/84174b62-fc76-4739-945f-7d618c1a4050/ReverseLunge.mp4",
        "type": "video/mp4",
        "size": 1323744
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539567,
    "default": false,
    "exercise_name": "哑铃前蹲 Dumbbell Front Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "48双哑铃前蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/713815bd-f69b-4ef5-bd41-dde201a8dde0/48.mp4",
        "type": "video/mp4",
        "size": 1764196
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539563,
    "default": false,
    "exercise_name": "侧卧髋外展 Leg Abduction",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "19侧卧抬腿（外展）.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/30c98c8e-9504-42fd-aeaf-1f81a43b23f5/19.mp4",
        "type": "video/mp4",
        "size": 1279084
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 539556,
    "default": false,
    "exercise_name": "单脚臀桥 Single Leg Hip Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "13臀桥（单脚）.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/166f8ea0-0d3d-4542-ac17-b4ea054d3983/13.mp4",
        "type": "video/mp4",
        "size": 1705596
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 538320,
    "default": false,
    "exercise_name": "窄握俯卧撑 Narrow Push up",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Narrow Push up.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/37ba6d61-8e8c-42d2-9dab-9a73d66813fc/NarrowPushup.mp4",
        "type": "video/mp4",
        "size": 1303477
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 538319,
    "default": false,
    "exercise_name": "跪姿过头蹲 Kneeling Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "跪姿髋伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e7020f22-fccc-47e1-a632-b1a029b67e1c/noroot.mp4",
        "type": "video/mp4",
        "size": 806924
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 538317,
    "default": false,
    "exercise_name": "哑铃平板推举 DB Bench Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB Bench Press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5494e39b-2053-4bf3-9d34-d75bc623b2c4/DBBenchPress.mp4",
        "type": "video/mp4",
        "size": 686930
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 538315,
    "default": false,
    "exercise_name": "哑铃侧平举 DB Lateral Raise",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "54哑铃侧平举.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4653066e-581c-4862-915e-0c87c2bc481d/54.mp4",
        "type": "video/mp4",
        "size": 1183388
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 538310,
    "default": false,
    "exercise_name": "面墙下蹲 Facing Wall Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Wall Sqaut.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ec3adeca-bb5b-430d-b06d-0d46f7b9ebc0/WallSqaut.mp4",
        "type": "video/mp4",
        "size": 811010
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 537555,
    "default": false,
    "exercise_name": "面墙天使 Wall Angel",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Wall Angel.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3e37c672-eb07-4153-8556-686962df62be/WallAngel.mp4",
        "type": "video/mp4",
        "size": 485194
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 537527,
    "default": false,
    "exercise_name": "单脚臀桥髋屈伸 Bridge With Hip Swing",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "hip bridge leg raise.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4c8babb7-f732-47f0-9440-b641b040764c/hipbridgelegraise.mp4",
        "type": "video/mp4",
        "size": 1422301
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 537516,
    "default": false,
    "exercise_name": "站姿髋外展Cable Hip Abduction",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "78站姿髋外展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2b3deed7-8e38-4ab9-b35c-51a5d0d58de8/78.mp4",
        "type": "video/mp4",
        "size": 1665314
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 536796,
    "default": false,
    "exercise_name": "背蹲 Back Squat ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "79箱式背蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8f9dd57e-af85-424e-81a5-0f91143dcf08/79.mp4",
        "type": "video/mp4",
        "size": 1611294
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 536524,
    "default": false,
    "exercise_name": "哑铃弓步蹲 DB Lunge",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "DB Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f824fe0b-e297-4ce5-b9ae-8996a0359fb3/DBLunge.mp4",
        "type": "video/mp4",
        "size": 2253877
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 536320,
    "default": false,
    "exercise_name": "哑铃下蹲",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "48双哑铃前蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d6348a48-965e-4e7d-b877-956b277e1cb5/48.mp4",
        "type": "video/mp4",
        "size": 1764196
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 535271,
    "default": false,
    "exercise_name": "仰卧腿升降 Leg Raise",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "18仰卧举腿.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/68fcc57f-0518-4fbf-b884-560cf53e6d0e/18.mp4",
        "type": "video/mp4",
        "size": 1239009
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534326,
    "default": false,
    "exercise_name": "跪姿弓步伸展 Kneeling Hip Extension Stretch",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "05前弓步伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/aae896eb-bc5f-4a39-b300-c3e9bb032cc7/05.mp4",
        "type": "video/mp4",
        "size": 1222960
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534329,
    "default": false,
    "exercise_name": "侧面摆腿 Side Leg Swing",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Side Leg Swing.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/c05495a2-b0df-4a17-842f-d80604eba716/SideLegSwing.mp4",
        "type": "video/mp4",
        "size": 1697325
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534271,
    "default": false,
    "exercise_name": "俯卧撑 Push up",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "41俯卧撑.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3f08e32c-dddd-4525-a5d9-1b8412f7509c/41.mp4",
        "type": "video/mp4",
        "size": 1975738
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534394,
    "default": false,
    "exercise_name": "后侧步弓步到平衡 Reverse Lunge to Balance",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "reverse lunge to balance.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/56d4e213-088c-477f-abf3-be9d3e05ca30/reverselungetobalance.mp4",
        "type": "video/mp4",
        "size": 900811
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534392,
    "default": false,
    "exercise_name": "背部 (每边) Lat ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "28泡沫轴背阔肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8042c274-7fa5-4db0-b5fc-a1aa501bd3cd/28.mp4",
        "type": "video/mp4",
        "size": 1698573
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534375,
    "default": false,
    "exercise_name": "俯身哑铃三头臂屈伸Bent DB Triceps Extension",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "57哑铃俯身臂屈伸.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/538fc011-c77d-42ca-b19d-f2b607c3ad40/57.mp4",
        "type": "video/mp4",
        "size": 1164101
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534370,
    "default": false,
    "exercise_name": "鸭步走 Duck Walk",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "duck walk.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f0ece022-256d-403b-a321-5927274d7991/duckwalk.mp4",
        "type": "video/mp4",
        "size": 3035552
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534368,
    "default": false,
    "exercise_name": "空蹲 Air Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Air Squat.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/cdeccc35-9e2b-4fd0-b643-1e0e7f5bd1ed/AirSquat.mp4",
        "type": "video/mp4",
        "size": 1584208
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534355,
    "default": false,
    "exercise_name": "虫爬 Inch Worm",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "33虫爬.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e7c4e2cd-72de-4e18-a8f0-94b65641ed10/33.mp4",
        "type": "video/mp4",
        "size": 1554288
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534341,
    "default": false,
    "exercise_name": "髋转动 Hip Rotation",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "01坐姿髋转动.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/b5e75a65-046a-4133-8463-29f83157b875/01.mp4",
        "type": "video/mp4",
        "size": 718776
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534339,
    "default": false,
    "exercise_name": "开合跳 Jumping Jack",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "64开合跳.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5108b8ab-991c-422a-92cf-ba48eb7543e0/64.mp4",
        "type": "video/mp4",
        "size": 1939622
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534274,
    "default": false,
    "exercise_name": "俯身髋伸展 Hip Extension",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "俯身髋伸展.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a287f3fc-b0a7-4e09-8d45-7aee75fd9e01/noroot.mp4",
        "type": "video/mp4",
        "size": 831894
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534254,
    "default": false,
    "exercise_name": "肩胛骨俯卧撑 Scapula Pushup",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Scapular pushup.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/dbdda6cf-711d-41a2-97b6-f4da3b7fda6a/Scapularpushup.mp4",
        "type": "video/mp4",
        "size": 2169231
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534251,
    "default": false,
    "exercise_name": "哑铃坐姿交替肩推 DB Seated Alternate Shoulder Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "哑铃交替肩推.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4b117fc7-12c9-4132-af27-c92c7fac3146/noroot.mp4",
        "type": "video/mp4",
        "size": 611525
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534249,
    "default": false,
    "exercise_name": "器械腿弯举 Leg Curl",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Leg Curl.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8a6c7bf4-f79a-4c57-9031-b82d0ca72260/LegCurl.mp4",
        "type": "video/mp4",
        "size": 2154770
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534246,
    "default": false,
    "exercise_name": "靠墙蹲 Wall Squat Hold",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Wall Squat Hold.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/4ece9623-bdba-4281-b5c3-76d1ff4354d0/WallSquatHold.mp4",
        "type": "video/mp4",
        "size": 1874868
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534234,
    "default": false,
    "exercise_name": "平板手支撑 Hand Plank",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Hand Plank.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/069ada41-a529-4ed2-8b85-597570c4e7ce/HandPlank.mp4",
        "type": "video/mp4",
        "size": 392065
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534240,
    "default": false,
    "exercise_name": "平板摸肩 Shoulder Tap",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "shoulder tap.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e2355160-3f01-47ab-81b3-4491b12686ca/shouldertap.mp4",
        "type": "video/mp4",
        "size": 353521
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534237,
    "default": false,
    "exercise_name": "杠铃平板卧推 Bench Chest Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Bench.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/88114104-1772-4de9-bb3a-a565d9b01c29/Bench.mp4",
        "type": "video/mp4",
        "size": 1887624
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534212,
    "default": false,
    "exercise_name": "坐姿髋转动(Hip Rotation To Extension)",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Hip Rotate.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1b945b7a-bbd9-424d-a207-3f69acb0b026/HipRotate.mp4",
        "type": "video/mp4",
        "size": 1985980
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534208,
    "default": false,
    "exercise_name": "坐姿髋转动(Hip Rotation To Extension)",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Hip Rotate.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/66cbea45-bff1-4eac-903e-985741a515eb/HipRotate.mp4",
        "type": "video/mp4",
        "size": 1985980
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532672,
    "default": false,
    "exercise_name": "站姿哑铃上推 Standing Shoulder Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "63实力肩推.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/38afbf90-0c81-4b66-a31c-ab3dc67c0488/63.mp4",
        "type": "video/mp4",
        "size": 1774832
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 525100,
    "default": false,
    "exercise_name": "哑铃坐姿推肩 DB Seated Shoulder Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "alt shoulder press.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/778f4164-5567-4c1e-9f8f-fe61816ae33e/altshoulderpress.mp4",
        "type": "video/mp4",
        "size": 2062749
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 484750,
    "default": false,
    "exercise_name": "站姿提踵 Heel Raise",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "75站姿提踵.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a922a99e-e5a6-4dc4-958a-b60a36dcc410/75.mp4",
        "type": "video/mp4",
        "size": 2140707
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532670,
    "default": false,
    "exercise_name": "杠铃胸前蹲 Front Squat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "67杠铃前蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5a8fee3e-6cb4-4ea0-b309-7db2d78814b1/67.mp4",
        "type": "video/mp4",
        "size": 2159455
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 523735,
    "default": false,
    "exercise_name": "哑铃俯身飞鸟 DB Bent Over Lateral Raise",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Bent Over lateral rasie.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9f2e75b1-4d41-48f9-8422-615bdbb3fab2/BentOverlateralrasie.mp4",
        "type": "video/mp4",
        "size": 3465227
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 523729,
    "default": false,
    "exercise_name": "蟹式伸展 Crab Reach",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Crab.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6664f6f4-513b-4f08-b884-6a99e3239f9e/Crab.mp4",
        "type": "video/mp4",
        "size": 3570986
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532850,
    "default": false,
    "exercise_name": "伟大拉伸 Greatest World Stretching",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "03环游世界伸展 (1).mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e1d3cf1c-0959-471c-92d7-d352ad960a8b/031.mp4",
        "type": "video/mp4",
        "size": 1792123
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 528913,
    "default": false,
    "exercise_name": "臀桥 Hip Press",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "12臀桥.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/54ea41cd-8e98-4e7e-ba92-3df71bfdb081/12.mp4",
        "type": "video/mp4",
        "size": 1830316
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 497638,
    "default": false,
    "exercise_name": "徒手过头蹲 Overhead Squat",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "OHS.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/298f66ee-0a32-419e-a7f3-d102bd8ca23f/OHS.mp4",
        "type": "video/mp4",
        "size": 1293854
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 527600,
    "default": false,
    "exercise_name": "徒手臂屈伸 Body Weight Triceps Extension ",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "dip.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a0433746-ce46-48c0-af9a-154e49517f67/dip.mp4",
        "type": "video/mp4",
        "size": 968713
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 528869,
    "default": false,
    "exercise_name": "死虫 Dead Bug",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "10死虫.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ea2aa77d-0021-428f-acb4-d225039ebd71/10.mp4",
        "type": "video/mp4",
        "size": 1487229
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532256,
    "default": false,
    "exercise_name": "躯干保持 Hollow Body",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "11身体凹陷.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9947bac4-ae7c-4df1-97af-e614b21be60b/11.mp4",
        "type": "video/mp4",
        "size": 2288993
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 482507,
    "default": false,
    "exercise_name": "器械坐姿下拉 Lat Pulldown",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "58坐姿下拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/36dc0815-1e2c-441a-ae89-89bec3b92b1d/58.mp4",
        "type": "video/mp4",
        "size": 1536176
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 482510,
    "default": false,
    "exercise_name": "器械坐姿划船 Seated Row",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "59坐姿划船.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/504f80f7-b7b7-4b56-b8d3-21ed44a03068/59.mp4",
        "type": "video/mp4",
        "size": 1815524
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 527598,
    "default": false,
    "exercise_name": "器械窄握高位下拉 Narrow Lat Pull Down",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Lat pulldown1.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d4b0b89d-a72a-4bc0-a597-27f69d808ef8/Latpulldown1.mp4",
        "type": "video/mp4",
        "size": 1750254
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 525109,
    "default": false,
    "exercise_name": "平板支撑 Plank",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "15平板支撑.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fa7b064d-27cd-4810-bc5f-56af429916a4/15.mp4",
        "type": "video/mp4",
        "size": 898078
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534116,
    "default": false,
    "exercise_name": "平板到下犬 Plank to Downward Dog",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "Plank to downward.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/a1639730-d413-476f-85e7-8a1033907da6/Planktodownward.mp4",
        "type": "video/mp4",
        "size": 2107571
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 497637,
    "default": false,
    "exercise_name": "罗马尼亚硬拉 Romania Deadlift",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "80罗马尼亚硬拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/f771ebe9-ed89-476c-bb38-2b047854dcbe/80.mp4",
        "type": "video/mp4",
        "size": 2004299
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532530,
    "default": false,
    "exercise_name": "猎鸟狗 Hunter Bird Dog",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "02猎鸟狗.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1d706404-4d16-434c-9256-cb217e2122e6/02.mp4",
        "type": "video/mp4",
        "size": 2368397
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532942,
    "default": false,
    "exercise_name": "靠墙站立 Wall Standing",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "37背墙站立.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9b5d3653-9c5f-49e3-a612-4eed2a802a20/37.mp4",
        "type": "video/mp4",
        "size": 734126
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532852,
    "default": false,
    "exercise_name": "交替侧弓步伸展 Cossack",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "32左右侧蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/ffef1028-f1b5-4434-8ad6-010896b2671c/32.mp4",
        "type": "video/mp4",
        "size": 1546325
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 523732,
    "default": false,
    "exercise_name": "后侧步弓步 Step Back Lunge",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Step Back Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/d0d5af93-68c6-4b4c-82e0-547ce52999f3/StepBackLunge.mp4",
        "type": "video/mp4",
        "size": 1435019
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532493,
    "default": false,
    "exercise_name": "滚轴-胸椎 Roller T Spine",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "25泡沫轴胸椎放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/57e86c2d-2a22-49e4-8fc0-b3675dcfa13f/25.mp4",
        "type": "video/mp4",
        "size": 2113459
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532501,
    "default": false,
    "exercise_name": "滚轴-臀部 Roller Mid Gluteus",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "29泡沫轴臀肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/334c88b6-2634-4f6f-8b8a-51a70017d518/29.mp4",
        "type": "video/mp4",
        "size": 1516807
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532505,
    "default": false,
    "exercise_name": "滚轴-腿内 Roller Inner Quad",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "30泡沫轴内收肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0fff6535-05fc-4f06-b741-c0407ff52e83/30.mp4",
        "type": "video/mp4",
        "size": 3091321
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532489,
    "default": false,
    "exercise_name": "滚轴-大腿 Roller Quad",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "26泡沫轴股四头肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/3031560f-43ac-45c5-8271-c96caf9913f8/26.mp4",
        "type": "video/mp4",
        "size": 2328010
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532497,
    "default": false,
    "exercise_name": "滚轴小腿 Roller Calf",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "27泡沫轴小腿三头肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/288bf00e-c941-45ae-8daa-3dc651a5d702/27.mp4",
        "type": "video/mp4",
        "size": 2229625
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532508,
    "default": false,
    "exercise_name": "滚轴-背部 Roller Lat",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "28泡沫轴背阔肌放松.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/8249b08f-a834-4c0c-936b-32d078bbe376/28.mp4",
        "type": "video/mp4",
        "size": 1698573
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 534082,
    "default": false,
    "exercise_name": "跪姿胸椎转 Kneeling T Spine Rotation",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "04跪姿胸椎旋转.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/cc4f99c2-3193-4ff1-8c96-fd4d094ebcba/04.mp4",
        "type": "video/mp4",
        "size": 1574646
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 497642,
    "default": false,
    "exercise_name": "弓步走 Walking Lunge",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Walking Lunge.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e853390f-32ac-41a9-b3ff-c2823de5b393/WalkingLunge.mp4",
        "type": "video/mp4",
        "size": 1496653
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 497640,
    "default": false,
    "exercise_name": "俯卧撑(板凳上) Bench Push up",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Bench Push up.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/5bc13a86-b580-4ff0-8b56-bc3712e860c5/BenchPushup.mp4",
        "type": "video/mp4",
        "size": 2020619
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 523734,
    "default": false,
    "exercise_name": "反向摇摆 Arch Body Rock",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "Arch Body Rock.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/9fbba7f8-90bb-4da8-88de-6fec2180ae77/ArchBodyRock.mp4",
        "type": "video/mp4",
        "size": 672508
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532258,
    "default": false,
    "exercise_name": "单腿平衡 Single Leg Balance",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "单脚平衡.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/6f1216cf-7645-4ae6-90b0-55b5b34db116/noroot.mp4",
        "type": "video/mp4",
        "size": 1625024
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 532920,
    "default": false,
    "exercise_name": "平衡单腿蹲 (底部起) Balance Lunge on Bench",
    "description": null,
    "url": "",
    "attachments": [
      {
        "name": "reverse lunge on bench.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/0495d54f-f94c-4d6b-b2cc-71432ff8f248/reverselungeonbench.mp4",
        "type": "video/mp4",
        "size": 1604300
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 525097,
    "default": false,
    "exercise_name": "单腿蹲 (板凳)Single Leg Squat on Bench",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "单腿蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/2d132e81-b7fa-486e-9b41-fe2b90264845/noroot.mp4",
        "type": "video/mp4",
        "size": 4214976
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 525092,
    "default": false,
    "exercise_name": "传统硬拉 Traditional Deadlift",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "38传统硬拉.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e080a4fb-6606-4b86-bf7e-040a2556e4cc/38.mp4",
        "type": "video/mp4",
        "size": 2508962
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 528914,
    "default": false,
    "exercise_name": "超人式 Superman",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "17超人式.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/fef2d310-46aa-49f2-8320-1e72f42fd1fa/17.mp4",
        "type": "video/mp4",
        "size": 2839708
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 528867,
    "default": false,
    "exercise_name": "侧平板支撑 Side Plank",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "16侧平板 (1).mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e6dcf3be-5d56-4f14-b6c4-8fec9227df63/161.mp4",
        "type": "video/mp4",
        "size": 1115328
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 531176,
    "default": false,
    "exercise_name": "蚌式 Clam",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "21蚌式.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/e90844cd-c755-4608-86bf-bfb49e8df5a6/21.mp4",
        "type": "video/mp4",
        "size": 1927479
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  },
  {
    "id": 528873,
    "default": false,
    "exercise_name": "板凳徒手下蹲 Air Squat on Bench",
    "description": null,
    "url": null,
    "attachments": [
      {
        "name": "板凳下蹲.mp4",
        "attachmentUrl": "https://com-thefitbot-uploadcare-data.s3.amazonaws.com/1767f194-a311-41ca-a0fd-38eb8f777135/noroot.mp4",
        "type": "video/mp4",
        "size": 750499
      }
    ],
    "trainer_id": 38406,
    "organization_id": 1,
    "tags": {
      "type": [],
      "primary_muscles": [],
      "plane": [],
      "pattern": []
    }
  }
]

const programs = [
  {
    "id": 1,
    "name": "CrossFit Perp | 16 Days",
    "length": null,
    "description": null,
    "updated_at": "2019-07-25T02:47:53.368Z",
    "created_at": "2019-07-25T02:47:53.368Z",
    "trainer_id": 1,
    "organization_id": 1,
    "sync_enabled": false,
    "shared": true,
    "highest_position": 3,
    "program_assignment_ids": [],
    "workout_ids": []
  },
]

Mock.setup({
  timeout: '200-500' // 表示响应时间介于 200 和 1000 毫秒之间，默认值是'10-100'。
})


Mock.mock('/oauth/token', 'post', {
  success: 0,
  token: 'thisisatoken',
  trainer_id: 1
});

Mock.mock('/auth/me', 'get', {
  success: 0,
  data: {
    user_id: 2
  }
}
);

Mock.mock('/users/1', 'get', {
  success: 0,
  user: rootUser,
  trainers,
  organizations,
  images,
});

Mock.mock('/users/2', 'get', {
  success: 0,
  user: orgTrainerUser,
  trainers,
  organizations,
  images,
});

Mock.mock('/users/3', 'get', {
  success: 0,
  user: rootUser,
  trainers,
  organizations,
  images,
});

Mock.mock('/trainers/2/clients', 'get', {
  success: 0,
  clients
});

Mock.mock(/\/workouts?.*/, 'get', function (options) {

  // console.log(options);

  if (options.url.indexOf("client_id") >= 0) {
    return {
      success: 0,
      users,
      clients,
      trainers,
      workouts,
      workout_items
    }
  } else if (options.url.indexOf("start_date") >= 0) {
    return {
      success: 0,
      users,
      clients,
      trainers,
      workouts,
      workout_items
    }
  } else if (options.url.indexOf("hide_from_feed") >= 0) {
    return {
      success: 0,
      users,
      clients,
      trainers,
      workouts,
      workout_items
    }
  }
});

Mock.mock('/exercises', 'get', {
  success: 0,
  exercises
});

Mock.mock('/warmups', 'get', {
  success: 0,
  warmups
});

Mock.mock('/cooldowns', 'get', {
  success: 0,
  cooldowns
});

Mock.mock('/programs', 'get', {
  success: 0,
  programs,
});



Mock.mock('/coach/login', 'post', {
  success: 0,
  data: {
    token: 'thisisatoken',
    user: orgTrainerUser
  }
});

Mock.mock('/api/register',
  'post',
  {
    "success": 0,
    "data": {
      token: 'thisisanewtoken',
      user: {
        name: '@name',
        age: 'age|1-10'
      }
    }
  });

Mock.mock('/api/users/', 'post',
  {
    "success": 0,
    "data": {
      token: 'thisisanewtoken',
      user: {
        name: '@name',
        age: 'age|1-10'
      }
    }
  });

Mock.mock('/api/workouts/1',
  'post',
  {
    "sucess": 0,
    "data": {
      "workouts": [
        {
          "id": 1,
          "due": "2019-08-05",
          "title": "W2 - D1",
          "short_description": "<p><b><u>W2 - D1</u></b></p><p class='name-and-info'>A) 膝盖俯卧撑 Kneeling Push up<br/>B) 杠铃俯身划船 BB Bend over Row<br/>C) 心肺循环<br/>D) 平板支撑 Plank<br/>E) 椭圆机<br/></p>",
          "created_at": "2019-08-05T03:56:43.535Z",
          "updated_at": "2019-08-05T11:33:39.784Z",
          "state": "completed",
          "rest_day": false,
          "rest_day_instructions": "",
          "warmup": "3 轮\n20 移动腿伸展 Walk Hamstring Stretch\n20 髋转动 Hip Rotation\n30秒 开合跳 Jumping Jack",
          "warmup_selected_exercises": [
            {
              "id": "556985",
              "name": "移动腿伸展 Walk Hamstring Stretch"
            },
            {
              "id": "534341",
              "name": "髋转动 Hip Rotation"
            },
            {
              "id": "534339",
              "name": "开合跳 Jumping Jack"
            }
          ],
          "cooldown_selected_exercises": [],
          "cooldown": null,
          "position": null,
          "order": 1,
          "uuid": "12df357b-fa9e-48de-9876-e98d8338c75e",
          "program_name": null,
          "hidden": false,
          "client_id": 249686,
          "comment_ids": [],
          "note_id": null,
          "program_id": null,
          "workout_item_ids": [
            1,
            2
          ]
        }
      ],
      "workout-items": [
        {
          "id": 1,
          "workout_id": 1,
          "name": "膝盖俯卧撑 Kneeling Push up",
          "info": "5x10\n组间休息1分钟\n下降到手肘的高度\n退阶：可以跪姿在板凳上",
          "result": "",
          "is_circuit": false,
          "state": "completed",
          "selected_exercises": [],
          "linked": false,
          "position": 1,
          "assessment_id": null,
          "created_at": "2019-08-05T03:56:43.542Z",
          "attachments": [],
          "exercise_id": 570134,
          "request_video": false
        },
        {
          "id": 2,
          "workout_id": 1,
          "name": "膝盖俯卧撑 Kneeling Push up",
          "info": "5x10\n组间休息1分钟\n下降到手肘的高度\n退阶：可以跪姿在板凳上",
          "result": "",
          "is_circuit": false,
          "state": "completed",
          "selected_exercises": [],
          "linked": false,
          "position": 2,
          "assessment_id": null,
          "created_at": "2019-08-05T03:56:43.542Z",
          "attachments": [],
          "exercise_id": 570134,
          "request_video": false
        }
      ]
    }
  });
