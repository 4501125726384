/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const mutations = {
  //change navbar button index
  coachPageIdx(state, payload){
    state.navPageIdx = payload;
  },

  adminPageIdx(state, payload){
    state.adminNavPageIdx = payload;
  },

  // // load from cache
  // auth_load(state){
  //   if (state && state.token && state.activeUser && state.role ) return
  //   let token = localStorage.getItem('token')
  //   let user = localStorage.getItem('user')
  //   let role = localStorage.getItem('role')
  //   let trainer = localStorage.getItem('trainer')
  //   let organization = localStorage.getItem('organization')
  //   if (token) state.token = token
  //   if (user) {
  //     state.activeUser = user;
  //     if (!state.users) state.users = [];
  //     if (state.users.length<=0) state.users.push(user);
  //   }
  //   if (role) state.role = role
  //   if (trainer) {
  //     state.activeTrainer = trainer;
  //     if (!state.trainers) state.trainers = [];
  //     if (!state.trainers.length) state.trainers.push(trainer);
  //   }
  //   if (organization) {
  //     state.activeOrganization = organization;
  //     if (!state.organizations) state.organizations = [];
  //     if (!state.organizations.length) state.organizations.push(organization);
  //   }
  // },

  // archive_client(state, payload) {
  //   payload.state = "archive"
  // },

  // active_client(state, payload) {
  //   payload.state = "active"
  // },

  // delete_trainer(state, payload) {
  //   if (this.trainers) {
  //     // let idx = this.trainers.indexOf(payload)
  //     // console.log(payload)
  //     //TODO delete ...
  //   }
  //   payload = null
  // },

  //update users[], clients[] and etc
  update_array(state, payload) {
    // console.log(payload)
    if (payload.reset) state[payload.name] = []
    let aryTag = state[payload.name]
    let arySrc = payload.data
    if (aryTag) {
      //check each item of source array in target array, replace or insert
      arySrc.forEach(itmSrc => {
        aryTag = aryTag.filter(itm => itm.id != itmSrc.id)
        aryTag.push(itmSrc)
        // aryTag.splice(aryTag.length, 0, itmSrc)
      });

      // sort_array(state, payload.name)
      let sortKeys = {
        "exercises": "exercise_name",
        "cooldowns": "name",
        "warmups": "name",
        "programs": "name",
        "organizations": "name",
        "users": "name"
      };
      let sortKey = sortKeys[payload.name];
      if (sortKey) {
        state[payload.name].sort((a, b) => a[sortKey] > b[sortKey] ? 1 : a[sortKey] < b[sortKey] ? -1 : 0);
      }

      // sort workout_item
      // if (payload.name == "workouts") {
      //   state["workouts"].forEach(wkItm => wkItm.workout_items.sort((a, b) => a.position - b.position));
      // }

      state[payload.name] = aryTag;
      // state[payload.name].splice(0, 0)

      // if (payload.name=="organizations"){
      //   state["activeOrganization"]
      // }
    }
  },

  sort_array(state, name) {
    let sortKeys = {
      "exercises": "exercise_name",
      "cooldowns": "name",
      "warmups": "name",
      "programs": "name",
      "organizations": "name",
      "users": "name"
    };
    let sortKey = sortKeys[name];
    if (sortKey) {
      state[name].sort((a, b) => a[sortKey] > b[sortKey] ? 1 : a[sortKey] < b[sortKey] ? -1 : 0);
    }
  },

  remove_array_item(state, payload) {
    let aryTag = state[payload.name]
    let arySrc = payload.data  //array of ids
    if (aryTag) {
      //check each item of source array in target array, replace or insert
      arySrc.forEach(itmSrc => {
        let idx = aryTag.findIndex(itm => itm.id == itmSrc.id || itm.id == itmSrc)
        if (idx >= 0) aryTag.splice(idx, 1)
      })
      state[payload.name] = aryTag
    }
  },

  updateActiveOrg(state, payload){
    if (payload && payload.id>0){
      state["activeOrganization"] = payload;
    }
  },

  updateProgramProperty(state, payload){
    if (payload && payload.id>0){
      // let index = state["programs"].findIndex(item => item.id == payload.id);
      // if (index) state["programs"].splice(index, 1, payload);
      let program = state["programs"].find(item => item.id == payload.id);
      if (program) program[payload.property] = payload.value;
    }
  },

  // 复制workout （粘贴只用）
  set_workout_copy(state, payload){
    let workout = JSON.parse(JSON.stringify(payload));

    // clear client info
    workout.id = 0;
    workout.client_id = 0;
    workout.due = null;
    workout.state = 'pending';
    workout.created_at = null;
    workout.updated_at = null;

    // replace workout_items with full object to let api re-create
    workout.workout_item_ids = [];
    workout.workout_items.forEach(item => {
      delete item["id"];
      delete item["workout_id"];
      delete item["created_at"];
      delete item["updated_at"];
      delete item["result"];
      item["state"]="pending";
    });

    state.workoutCopy = workout;
  },

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width;
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_BREAKPOINT(state, val) {
    state.breakpoint = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_USER_ROLE(state, val) {
    state.userRole = val
    localStorage.setItem('userRole', val)
  },
  // UPDATE_STATUS_CHAT(state, value) {
  //     state.AppActiveUser.status = value;
  // },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },


  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
    // update the main list
    state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList.data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.index == payload.index)
      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // The Navbar
  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })
    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },
}

export default mutations
