/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Store from './store/store'
import Router from 'vue-router'
// import state from './store/state'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          name: 'coach-home',
          component: () => import('./views/CoachHome.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('./views/pages/profile/Profile.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/clients',
          name: 'clients',
          component: () => import('@/views/pages/Clients.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/clients/:slug/workouts/:id?',
          name: 'clent-workouts',
          component: () => import('@/views/pages/Workouts.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/library',
          name: 'library',
          component: () => import('@/views/pages/Library.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/team',
          name: 'team',
          component: () => import('@/views/pages/Team.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/programs',
          name: 'programs',
          component: () => import('@/views/pages/Programs.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        },
        {
          path: '/programs/:id/workouts',
          name: 'programs-workouts',
          component: () => import('@/views/components/coach/program/ProgramWorkouts.vue'),
          meta: {
            roles: ['trainer', 'admin', 'super']
          }
        }
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/admin/login',
          name: 'page-admin-login',
          component: () => import('@/views/pages/admin/AdminLogin.vue')
        },
        {
          path: '/register',
          name: 'page-register',
          component: () => import('@/views/pages/Register.vue')
        },
        {
          path: '/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
      ]
    },
    {
      path: '',
      component: () => import('./layouts/main/MainAdmin.vue'),
      children: [
        // {
        //   path: '/admin',
        //   name: 'admin-home',
        //   component: () => import('./views/pages/admin/AdminHome.vue'),
        //   meta: {
        //     roles: ['admin']
        //   }
        // },
        {
          path: '/admin',
          name: 'admin-home',
          redirect: { name: "admin-organizations" },
          meta: {
            roles: ['super']
          }
        },
        {
          path: '/admin/organizations',
          name: 'admin-organizations',
          component: () => import('./views/pages/admin/AdminOrganizations.vue'),
          meta: {
            roles: ['super']
          }
        },
        {
          path: '/admin/trainers',
          name: 'admin-trainers',
          component: () => import('./views/pages/admin/AdminTrainers.vue'),
          meta: {
            roles: ['super']
          }
        },
        {
          path: '/admin/clinets',
          name: 'admin-clinets',
          component: () => import('./views/pages/admin/AdminClients.vue'),
          meta: {
            roles: ['super']
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})


// function loginFromCache() {
//   let lastLoginOn = localStorage.getItem('timestamp')
//   let expiredDate = new Date();
//   expiredDate.addDays(-365);
//   lastLoginOn = lastLoginOn ? (new Date(lastLoginOn)) : expiredDate;
//   let hoursPassed = (new Date() - lastLoginOn) / 1000 / 60 / 60;
//   let bRtn = false;
//   if (hoursPassed < 24) {
//     const token = localStorage.getItem('token')
//     if (token) {
//       axios.defaults.headers.common['Authorization'] = "Bearer " + token
//       bRtn = true
//     }
//   }

//   return bRtn
// }

router.beforeEach((to, from, next) => {
  let isAdmin = to.path.startsWith('/admin')
  Store.commit('auth/auth_load', {rootState:Store.state, is_admin:isAdmin})
  if (to.path !== '/login' && to.path !== '/admin/login' && to.meta && to.meta.roles && to.meta.roles.length > 0) {
    // console.log(Store.getters['auth/isLoggedIn'], Store.getters['auth/roleName']);
    if (Store.getters['auth/isLoggedIn'] && to.meta.roles.includes(Store.getters['auth/roleName'])) {
      return next()
    } else {
      let path = '/login'
      if (isAdmin) {
        path = '/admin/login'
      }
      router.push({ path, query: { to: to.path } })
    }
  } else {
    return next()
  }
})

export default router
