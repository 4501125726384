
export default {
  isLoggedIn: state => !!state.token,
  isAdministrator: state => state.role == "admin",
  isTrainer: state => state.role == "trainer",
  roleName: state => state.role,
  authStatus: state => state.status,
  renewTokens() {
    // reject can be used as parameter in promise for using reject
    return new Promise((resolve) => {
      if (localStorage.getItem('token') !== "true") {
        //reject("Not logged in");
      } else {
        resolve(true);
      }
    })
  },
}
