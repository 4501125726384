// import { stat } from "fs";

/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	// UPDATE_AUTHENTICATED_USER(state, user) {
	// 	localStorage.setItem('userInfo', JSON.stringify(user));
	// 	localStorage.setItem('userRole', 'trainer');
  // },

  auth_load(state, payload){
    if (state && state.token && state.activeUser && state.role ) return

    let token = localStorage.getItem('token')
    let user = localStorage.getItem('user')
    let role = localStorage.getItem('role')
    let trainer = localStorage.getItem('trainer')
    let organization = localStorage.getItem('organization')
    let rootState = payload.rootState;

    if (payload.is_admin && role!="super") return;
    if (!payload.is_admin && role=="super") return;

    if (token) state.token = token;
    if (user) {
      user = JSON.parse(user);
      rootState.activeUser = state.activeUser = user;
      if (!rootState.users) rootState.users = [];
      if (rootState.users.length<=0) rootState.users.push(user);
    }
    if (role) rootState.role = state.role = role;
    if (trainer) {
      trainer = JSON.parse(trainer);
      rootState.activeTrainer = trainer;
      if (!rootState.trainers) rootState.trainers = [];
      if (!rootState.trainers.length) rootState.trainers.push(trainer);
    }
    if (organization) {
      organization = JSON.parse(organization);
      rootState.activeOrganization = organization;
      if (!rootState.organizations) rootState.organizations = [];
      if (!rootState.organizations.length) rootState.organizations.push(organization);
    }
  },

  auth_request(state){
    state.status = 'loading'
  },

  auth_success(state, payload){
    state.status = 'success'
    if (payload.token) state.token = payload.token
    console.log(payload)
    state.activeUser = payload.user
    state.role = payload.role
    payload.rootState.activeUser = payload.user
    payload.rootState.activeTrainer = payload.trainer
    payload.rootState.activeOrganization = payload.organization

    localStorage.setItem('token', payload.token)
    localStorage.setItem('user', JSON.stringify(payload.user))
    localStorage.setItem('role', payload.role)
    localStorage.setItem('trainer', JSON.stringify(payload.trainer))
    localStorage.setItem('organization', JSON.stringify(payload.organization))
    localStorage.setItem("timestamp", (new Date()).getTime())
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = null
    state.token = null
    state.activeUser = null
    state.role = null
  },
}
