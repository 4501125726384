/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from 'axios'
import DTUtil from "@/assets/utils/datetime.js";

const actions = {
  // // base information for each page
  // getBaseInfo({ commit }, payload){
  //   //
  // },

  getWorkouts({ commit }, payload) {
    axios.get("/workouts", {
      params: {
        ids: payload.ids.join(",")
      }
    }).then(resp => {
      // console.log(resp);
      if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });
      if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] });
      if (resp.data["comments"]) commit("update_array", { name: "comments", data: resp.data["comments"] });
      if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] });
      if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
      if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] });
      if (resp.data["workout_items"]) commit("update_array", { name: "workout_items", data: resp.data["workout_items"] });

      if (payload.callback) { payload.callback() }
    })
    // .catch(err => console.log(err));
  },

  getWorkoutsByDate({ commit }, payload) {
    axios.get("/workouts", {
      // params: {
      //   active: true,
      //   due_today: true,
      //   pending: true,
      //   rest_day: false,
      //   trainer_id: payload.trainer_id
      // }
      // params: {
      //   start_date: payload.today,
      //   end_date: payload.today,
      //   limit: 100,
      //   order: 'due_ASC'
      // }
      params: payload
    }).then(resp => {
      // console.log(resp);
      // debugger
      if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });
      if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] });
      if (resp.data["comments"]) commit("update_array", { name: "comments", data: resp.data["comments"] });
      if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] });
      if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
      if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] });
      if (resp.data["workout_items"]) commit("update_array", { name: "workout_items", data: resp.data["workout_items"] });
    })
    // .catch(err => console.log(err));
  },

  // 最近更新的前20个workouts
  getRecentWrokouts({ commit }, payload) {
    axios.get("/workouts", {
      params: {
        hide_from_feed: false,
        limit: 20,
        page: 1,
        rest_day: false,
        order: "updated_at_DESC",
        state: "completed",
        trainer_id: payload.trainer_id
      }
    }).then(resp => {
      // console.log(resp);
      if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });
      if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] });
      if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] });
      if (resp.data["comments"]) commit("update_array", { name: "comments", data: resp.data["comments"] });
      if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
      if (resp.data["workout_items"]) commit("update_array", { name: "workout_items", data: resp.data["workout_items"] });
      if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] });
    })
    // .catch(err => console.log(err));
  },

  updateWorkout({ commit, dispatch }, payload) {
    axios.put("/workouts/" + payload.workout.id, payload)
      .then(resp => {
        // console.log(resp);
        if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });
        if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] });
        if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] });
        if (resp.data["comments"]) commit("update_array", { name: "comments", data: resp.data["comments"] });
        if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
        if (resp.data["workout_items"]) commit("update_array", { name: "workout_items", data: resp.data["workout_items"] });
        if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] });
        if (resp.data["workout"]) commit("update_array", { name: "workouts", data: [resp.data["workout"]] });

        dispatch("getWorkout", [payload.workout.id]);
      })
    // .catch(err => console.log(err));
  },

  createWorkout({ commit, dispatch }, payload) {
    axios.post("/workouts", payload)
      .then(resp => {
        // console.log(resp);
        let workout = resp.data["workout"];
        if (workout) {
          commit("update_array", { name: "workouts", data: [workout] });
          commit("update_array", { name: "workout_items", data: workout["workout_items"] });

          // update programe length & highest_position & workout_ids
          if (payload.program && payload.program.highest_position < payload.workout.position) {
            let program = JSON.parse(JSON.stringify(payload.program));
            program.highest_position = payload.workout.position;

            let num = Math.trunc((program.highest_position + 6) / 7);
            program.length = (num ? num : 1) * 7;

            if (program.workout_ids) program.workout_ids.push(workout.id);

            dispatch("updateProgram", { program: program });
          }
        }
      })
    // .catch(err => console.log(err));
  },

  deleteWorkout({ commit, dispatch }, payload) {
    let workout_id = payload.workout?payload.workout.id:payload;
    axios.delete("/workouts/" + workout_id)
      .then(resp => {
        // console.log(resp);
        if (resp.data.success == 0) {
          // remove_array_item("workout_items", payload);
          commit("remove_array_item", { name: "workouts", data: [payload] });

          // update programe length & highest_position & workout_ids
          if (payload.program) {
            let program = JSON.parse(JSON.stringify(payload.program));
            if (program.highest_position!=payload.highest_position) {
              program.highest_position=payload.highest_position;
            }

            let num = Math.trunc((program.highest_position + 6) / 7);
            program.length = (num ? num : 1) * 7;

            let index = program.workout_ids.find(item=>item.id==workout_id);
            if (index>=0) {
              program.workout_ids.splice(index, 1);
            }

            dispatch("updateProgram", { program: program });
          }
        }
      })
    // .catch(err => console.log(err));
  },


  deleteWorkoutItem({ commit }, payload) {
    axios.delete("/workout_items/" + payload)
      .then(resp => {
        // console.log(resp);
        commit("remove_array_item", { name: "workout_items", data: [payload.id], resp: resp })
      })
    // .catch(err => console.log(err));
  },


  updateWorkoutItem({ commit }, payload) {
    axios.put("/workout_items/" + payload.id, {"workout_item":payload})
      .then(resp => {
        // console.log(resp);
          commit("update_array", { name: "workout_items", data: resp.data["workout_item"] });
      })
    // .catch(err => console.log(err));
  },

  // deleteWorkout({ commit }, payload){
  //   axios.delete("/workouts/"+payload.id)
  //     .then(resp => {
  //       console.log(resp);
  //     })
  //     .catch(err => console.log(err));
  // },

  // 粘贴 workout， payload={workout_src, client_id, day}
  pasteWorkout({ dispatch }, payload) {
    let workout = JSON.parse(JSON.stringify(payload.workout_src));

    // replace with new client information
    delete workout["id"];
    workout.client_id = payload.client_id;
    if (payload.day) workout.due = payload.day.getFullYear() + '-' + (payload.day.getMonth() + 1) + '-' + payload.day.getDate();
    if (payload.position) workout.position = payload.position;
    if (payload.program){
      workout.program_id = payload.program.id;
    }

    dispatch("createWorkout", { "workout": workout, "program": payload.program });
  },

  // ////////////////////////////////////////////
  // organization
  // ////////////////////////////////////////////
  getOrganizations({ commit }, payload) {
    let vm = payload?payload.vm:null;
    axios.get("/organizations")
      .then(resp => {
        if (commit) {
          commit("update_array", { name: "organizations", data: resp.data["organizations"] });
        }

        if (vm) vm.$vs.notify({
          title: '成功',
          text: "组织信息获取成功",
          color: 'succes',
          position: 'top-right'
        })
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  createOrganization({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm
    axios.post("/organizations", payload.organization)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          // commit("sort_array", "users");
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "组织创建成功",
            color: 'succes',
            position: 'top-right'
          });

          if (commit) {
            commit("update_array", { name: "organizations", data: [resp.data["organization"]] });
          }
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response ? err.response.data.msg : "Error",
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  updateOrganization({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm
    axios.put("/organizations/" + payload.organization.id, payload.organization)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          // commit("sort_array", "users");
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "组织信息修改成功",
            color: 'succes',
            position: 'top-right'
          });

          if (commit) {
            commit("update_array", { name: "organizations", data: [resp.data["organization"]] });
          }
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  deleteOrganization({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm
    axios.delete("/organizations/" + payload.organization.id)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.message == "ok") {
          // commit("sort_array", "users");
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "组织删除成功",
            color: 'succes',
            position: 'top-right'
          });

          if (commit) {
            commit("remove_array_item", { name: "organizations", data: [payload.organization] });
          }
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  // ////////////////////////////////////////////
  // user
  // ////////////////////////////////////////////
  updateUser({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm
    axios.put("/users/" + payload.user.id, { "user": payload.user })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          commit("sort_array", "users");

          if (commit) {
            // commit("update_array", { name: "users", data: resp.data["users"] });
          }
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  changePassword({ commit }, payload) {
    let vm = payload.vm
    if (commit) {/**/ }
    axios.post("/users/change_password", { "user": payload.user })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0 && vm) {
          vm.$vs.notify({
            title: '成功',
            text: "密码修改成功",
            color: 'success',
            position: 'top-right'
          })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  getSMSCode({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm
    axios.post("/user/sms", { "mobile": payload.mobile })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          // commit("sort_array", "users");

          if (commit) {
            // commit("update_array", { name: "users", data: resp.data["users"] });
          }
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  resetPassword({ commit }, payload) {
    let vm = payload.vm;
    let callback = payload.callback;

    if (commit) {/**/ }
    axios.post("/user/reset_password", payload.userDetails)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "密码重置成功",
            color: 'success',
            position: 'top-right'
          });

          if (callback) callback();
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  registerTrainer({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm;
    let callback = payload.callback;
    axios.post("trainers/register", { "user": payload.userDetails })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          if (vm) vm.$vs.notify({
            title: '成功',
            text: resp.data.msg,
            color: 'success',
            position: 'top-right'
          });

          if (commit) {
            // commit("update_array", { name: "users", data: resp.data["users"] });
          }

          if (callback) callback();
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  // ////////////////////////////////////////////
  // client
  // ////////////////////////////////////////////
  createClient({ dispatch }, payload) {
    // console.log(payload);
    let vm = payload.vm
    axios.post("/users", { "user": payload.user })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          payload.client.user_id = resp.data.user.id
          if (payload.active) payload.client.state = "active";
          axios.post("/clients", { "client": payload.client })
            .then(resp => {
              if (resp.data.success == 0) {
                vm.$vs.notify({
                  title: "成功",
                  text: "学员添加成功",
                  color: 'success',
                  position: 'top-right'
                })

                // temp fix rate=100
                let newClient = resp.data.client;
                newClient.compliance_rate_for_7_days = 100;
                newClient.compliance_rate_for_30_days = 100;
                newClient.compliance_rate_for_90_days = 100;
                dispatch("updateClient", {client:newClient});

                dispatch("getTeam", payload.client.organization_id);
              } else {
                vm.$vs.notify({
                  title: "失败",
                  text: "学员添加失败",
                  color: 'danger',
                  position: 'top-right'
                })
              }
            })
            .catch(err => {
              // console.log(err.response.data.msg)
              vm.$vs.notify({
                title: '错误',
                text: err.response.data.msg,
                color: 'danger',
                position: 'top-right'
              })
            })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  updateClient({ commit }, payload) {
    let vm = payload.vm
    axios.put("/clients/" + payload.client.id, { "client": payload.client })
      .then(resp => {
        commit("update_array", { name: "clients", data: [resp.data["client"]] });
        if (vm) {
          vm.$vs.notify({
            title: "成功",
            text: "学员修改成功",
            color: 'success',
            position: 'top-right'
          });
        } else {
          vm.$vs.notify({
            title: "失败",
            text: "学员修改失败",
            color: 'danger',
            position: 'top-right'
          });
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        });
      })
  },

  deleteClient({ commit }, payload) {
    let vm = payload.vm
    axios.delete("/users/" + payload.client.user_id)
    .then(userResp =>{
      if (!userResp.data.success==0){
        if (vm ) {
          vm.$vs.notify({
            title: "失败",
            text: "学员用户删除不成功",
            color: 'danger',
            position: 'top-right'
          });
          return;
        }
      }

      axios.delete("/clients/" + payload.client.id)
      .then(resp => {
        commit("remove_array_item", { name: "clients", data: [payload.client.id] });
        commit("remove_array_item", { name: "users", data: [payload.client.user_id] });
        if (vm && resp.data.success==0) {
          vm.$vs.notify({
            title: "成功",
            text: "学员删除成功",
            color: 'success',
            position: 'top-right'
          });
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        });
      })
    });
  },

  getClients({ commit }, payload) {
    let trainer_id = payload.trainer_id
    axios.get("/trainers/" + trainer_id + "/clients", { "client": payload.client })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });
        if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] });
        if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] });
        if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
        if (resp.data["organizations"]) {
          commit("update_array", { name: "organizations", data: resp.data["organizations"] });
          commit("updateActiveOrg", resp.data["organizations"][0]);
        }
        if (resp.data["workout_items"]) commit("update_array", { name: "workout_items", data: resp.data["workout_items"] });
        if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] });
      })
    // .catch(err => {
    //   console.log(err)
    // })
  },

  getAllClients({ commit }) {
    axios.get("/clients")
      .then(resp => {
        // console.log(resp.success)
        if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });
        if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] });
        if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] });
        if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
        if (resp.data["workout_items"]) commit("update_array", { name: "workout_items", data: resp.data["workout_items"] });
        if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] });
      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  // ////////////////////////////////////////////
  // trainer
  // ////////////////////////////////////////////
  getTrainers({ commit }, payload) {
    let vm = payload ? payload.vm : null;
    let critical = (payload && payload.critical) ? payload.critical : null;
    let callback = (payload && payload.callback) ? payload.callback : null;

    axios.get("/trainers", critical)
      .then(resp => {
        if (resp.data.success == 0) {
          if (resp.data["trainers"]) commit("update_array", { name: "trainers", data: resp.data["trainers"] });
          if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] });

          if (callback) callback();
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  createTrainer({ commit, dispatch }, payload) {
    // console.log(payload);
    let vm = payload.vm
    //create user first
    axios.post("/users", { "user": payload.user })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          commit("sort_array", "users");
          payload.trainer.user_id = resp.data.user.id
          axios.post("/trainers", { "trainer": payload.trainer })
            .then(resp => {
              if (resp.data.success == 0) {
                commit("sort_array", "trainers");
                vm.$vs.notify({
                  title: "成功",
                  text: "教练" + payload.user.name + "创建成功",
                  color: 'success',
                  position: 'top-right'
                })
                if (payload.trainer.organization_id) dispatch("getTeam", payload.trainer.organization_id)
              }
            })
            .catch(err => {
              // console.log(err.response.data.msg)
              vm.$vs.notify({
                title: '错误',
                text: err.response.data.msg,
                color: 'danger',
                position: 'top-right'
              })
            })

          if (commit) {
            //console.log('')
          }
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  updateTrainer({ commit }, payload) {
    let trainer = payload.trainer
    let vm = payload.vm

    axios.put("/trainers/" + trainer.id, {trainer:payload.trainer})
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          if (resp.data["trainer"]) commit("update_array", { name: "trainers", data: [resp.data["trainer"]] });
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "教练更新成功",
            color: 'sucess',
            position: 'top-right'
          });
        }
      })
      .catch(err => {
        if (vm) vm.$vs.notify({
          title: '成功',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  transferTrainer({ dispatch }, payload) {
    let vm = payload.vm

    axios.post("/trainer_client_transfers", {trainer:payload.trainer})
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          // if (resp.data["trainer"]) commit("update_array", { name: "trainers", data: [resp.data["trainer"]] });
          dispatch("getTeam", payload.organization_id);
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "教练更新成功",
            color: 'sucess',
            position: 'top-right'
          });
        }
      })
      .catch(err => {
        vm.$vs.notify({
          title: '成功',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  /**
   * 删除教练
   * @param {*} payload -> trainer object
   */
  deleteTrainer({ commit }, payload) {
    let trainer = payload.trainer
    let vm = payload.vm

    commit("delete_trainer", trainer)

    axios.delete("/trainers/" + trainer.id)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          commit("remove_array_item", { name: "trainers", data: [payload.trainer] });
          axios.delete("/users/" + trainer.user_id)
            .then(resp => {
              if (resp.data.success == 0) {
                commit("remove_array_item", { name: "users", data: [payload.user] });
                vm.$vs.notify({
                  title: '成功',
                  text: "教练删除成功",
                  color: 'sucess',
                  position: 'top-right'
                });
              }
            })
        }
      })
      .catch(err => {
        vm.$vs.notify({
          title: '成功',
          text: "教练删除失败：" + err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  // ////////////////////////////////////////////
  // exercise
  // ////////////////////////////////////////////
  getExercises({ commit }, orgId) {
    axios.get("/exercises")
      .then(resp => {
        // console.log(resp.success)
        let ary = orgId ?
          resp.data["exercises"].filter(item => item.organization_id == orgId) :
          resp.data["exercises"];

        commit("update_array", { name: "exercises", data: ary, reset: true });
        // commit("sort_array", "exercises");
      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  updateExercise({ commit }, payload) {
    let vm = payload.vm;
    axios.put("/exercises/" + payload.exercise.id, { "exercise": payload.exercise })
      .then(resp => {
        // console.log(resp);
        // if (resp.data["attachments"]) commit("update_array", { name: "attachments", data: resp.data["attachments"] });
        let ex = resp.data["exercise"];
        if (ex) {
          commit("update_array", { name: "exercises", data: [ex] });
          if (ex.attachments && ex.attachments.length>0){
            commit("update_array", { name: "attachments", data: ex.attachments });
          }
        }
        commit("sort_array", "exercises");
        vm.$vs.notify({
          title: '成功',
          text: "练习运动保存成功",
          color: 'sucess',
          position: 'top-right'
        });
      })
    // .catch(err => console.log(err));
  },

  createExercise({ commit }, payload) {
    let vm = payload.vm;
    axios.post("/exercises", { "exercise": payload.exercise })
      .then(resp => {
        // console.log(resp);
        if (resp.data["attachments"]) commit("update_array", { name: "attachments", data: resp.data["attachments"] });
        if (resp.data["exercise"]) commit("update_array", { name: "exercises", data: [resp.data["exercise"]] });
        commit("sort_array", "exercises");
        vm.$vs.notify({
          title: '成功',
          text: "练习运动保存成功",
          color: 'sucess',
          position: 'top-right'
        })
      })
    // .catch(err => console.log(err));
  },

  deleteExercise({ commit }, payload) {
    let vm = payload.vm;
    axios.delete("/exercises/" + payload.exercise.id)
      .then(resp => {
        // console.log(resp);
        if (resp.data["success"] == 0) commit("remove_array_item", { name: "exercises", data: [payload.exercise] });
        commit("sort_array", "exercises");
        vm.$vs.notify({
          title: '成功',
          text: "练习运动删除成功",
          color: 'sucess',
          position: 'top-right'
        })
      })
    // .catch(err => console.log(err));
  },

  // ////////////////////////////////////////////
  // warmup
  // ////////////////////////////////////////////
  getWarmups({ commit }, orgId) {
    axios.get("/warmups")
      .then(resp => {
        // console.log(resp.success)
        let ary = orgId ?
          resp.data["warmups"].filter(item => item.organization_id == orgId) :
          resp.data["warmups"];

        commit("update_array", { name: "warmups", data:ary, reset:true });
        // commit("sort_array", "warmups");
      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  updateWarmup({ commit }, payload) {
    let vm = payload.vm;
    axios.put("/warmups/" + payload.warmup.id, { "warmup": payload.warmup })
      .then(resp => {
        // console.log(resp);
        // if (resp.data["attachments"]) commit("update_array", { name: "attachments", data: resp.data["attachments"] });
        if (resp.data["warmup"]) commit("update_array", { name: "warmups", data: [resp.data["warmup"]] });
        commit("sort_array", "warmups");
        vm.$vs.notify({
          title: '成功',
          text: "热身运动保存成功",
          color: 'sucess',
          position: 'top-right'
        })
      })
    // .catch(err => console.log(err));
  },

  createWarmup({ commit }, payload) {
    axios.post("/warmups", { "warmup": payload.warmup })
      .then(resp => {
        // console.log(resp);
        // if (resp.data["attachments"]) commit("update_array", { name: "attachments", data: resp.data["attachments"] });
        let warmup = resp.data["warmup"];
        if (warmup) commit("update_array", { name: "warmups", data: [warmup] });
        commit("sort_array", "warmups");
      })
    // .catch(err => console.log(err));
  },

  deleteWarmup({ commit }, payload) {
    let vm = payload.vm;
    axios.delete("/warmups/" + payload.warmup.id)
      .then(resp => {
        // console.log(resp);
        if (resp.data["success"] == 0) commit("remove_array_item", { name: "warmups", data: [payload.warmup] });
        commit("sort_array", "warmups");
        vm.$vs.notify({
          title: '成功',
          text: "热身运动删除成功",
          color: 'sucess',
          position: 'top-right'
        })
      })
    // .catch(err => console.log(err));
  },

  // ////////////////////////////////////////////
  // cooldown
  // ////////////////////////////////////////////
  getCooldowns({ commit }, orgId) {
    axios.get("/cooldowns")
      .then(resp => {
        // console.log(resp.success)
        let ary = orgId ?
          resp.data["cooldowns"].filter(item => item.organization_id == orgId) :
          resp.data["cooldowns"];
        commit("update_array", { name: "cooldowns", data: ary, reset:true });
        // commit("sort_array", "cooldowns");
      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  updateCooldown({ commit }, payload) {
    axios.put("/cooldowns/" + payload.cooldown.id, { "cooldown": payload.cooldown })
      .then(resp => {
        // console.log(resp);
        // if (resp.data["attachments"]) commit("update_array", { name: "attachments", data: resp.data["attachments"] });
        if (resp.data["cooldown"]) commit("update_array", { name: "cooldowns", data: [resp.data["cooldown"]] });
        commit("sort_array", "cooldowns");
      })
    // .catch(err => console.log(err));
  },

  createCooldown({ commit }, payload) {
    axios.post("/cooldowns", { "cooldown": payload.cooldown })
      .then(resp => {
        // console.log(resp);
        // if (resp.data["attachments"]) commit("update_array", { name: "attachments", data: resp.data["attachments"] });
        if (resp.data["cooldown"]) commit("update_array", { name: "cooldowns", data: [resp.data["cooldown"]] });
        commit("sort_array", "cooldowns");
      })
    // .catch(err => console.log(err));
  },

  deleteCooldown({ commit }, payload) {
    let vm = payload.vm;
    axios.delete("/cooldowns/" + payload.cooldown.id)
      .then(resp => {
        // console.log(resp);
        if (resp.data["success"] == 0) commit("remove_array_item", { name: "cooldowns", data: [payload.cooldown] });
        commit("sort_array", "cooldowns");
        vm.$vs.notify({
          title: '成功',
          text: "放松运动删除成功",
          color: 'sucess',
          position: 'top-right'
        })
      })
    // .catch(err => console.log(err));
  },

  // ////////////////////////////////////////////
  // comment
  // ////////////////////////////////////////////
  createComment({ commit }, payload) {
    axios.post("/comments", { "comment": payload.comment })
      .then(resp => {
        // console.log(resp);
        if (resp.data["comment"]) commit("update_array", { name: "comments", data: [resp.data["comment"]] });
        commit("sort_array", "comments");
        // dispatch("")
      })
    // .catch(err => console.log(err));
  },

  deleteComment({ commit }, payload) {
    axios.delete("/comments/" + payload.id)
      .then(resp => {
        // console.log(resp);
        if (resp.data["comment"]) commit("update_array", { name: "comments", data: [resp.data["comment"]] });
        commit("remove_array_item", "comments", [payload.id]);
        // dispatch("")
      })
    // .catch(err => console.log(err));
  },

  // ////////////////////////////////////////////
  // program
  // ////////////////////////////////////////////
  getPrograms({ commit }, orgId) {
    axios.get("/programs")
      .then(resp => {
        // console.log(resp.success)
        let ary = orgId ?
          resp.data["programs"].filter(item => item.organization_id == orgId) :
          resp.data["programs"];
        if (resp.data["programs"]) commit("update_array", { name: "programs", data: ary, reset:true });
      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  createProgram({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm

    axios.post("/programs", payload.program)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          commit("update_array", { name: "programs", data: [resp.data["program"]] });
          vm.$vs.notify({
            title: '成功',
            text: "新标准训练计划表创建成功。",
            color: 'success',
            position: 'top-right'
          })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  updateProgram({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm

    axios.put("/programs/" + payload.program.id, payload.program)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          commit("update_array", { name: "programs", data: [resp.data["program"]] });
          if (vm) vm.$vs.notify({
            title: '成功',
            text: "准训练计划表更新成功。",
            color: 'success',
            position: 'top-right'
          })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  deleteProgram({ commit }, payload) {
    // console.log(payload);
    let vm = payload.vm

    axios.delete("/programs/" + payload.program.id)
      .then(resp => {
        // console.log(resp.success)
        if (resp.data.success == 0) {
          commit("remove_array_item", { name: "programs", data: [payload.program] });
          if (vm) vm.$vs.notify({
            title: '成功',
            text: payload.program.name + "删除",
            color: 'success',
            position: 'top-right'
          })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  assignJob({ dispatch }, payload) {
    let vm = payload.vm;

    axios.post("/programs/" + payload.program_id + "/assign_job", payload.params)
      .then(resp => {
        // console.log(resp.success)
        // if (commit){
        //   console.log(resp.data);
        // }
        if (resp.data.success == 0) {
          dispatch("getPrograms");

          if (vm) vm.$vs.notify({
            title: '成功',
            text: "应用成功",
            color: 'success',
            position: 'top-right'
          })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  assignJob2({ commit, dispatch }, payload) {
    let vm = payload.vm;

    //get all client's workout in date range, remove them
    payload.params.client_ids.forEach(client_id => {
      axios.get("/workouts", {
        params: {
          client_id: client_id,
          start_date: payload.params.start_date,
          end_date: payload.params.end_date,
          limit: 1000,
          order: "due_ASC"
        }
      })
      .then(resp => {
        let workouits = resp.data["workouts"];
        workouits.forEach(workout => {
          // dispatch("deleteWorkout", workout.id)
          axios.delete("/workouts/" + workout.id)
          .then(resp => {
            if (resp.data.success == 0) {
              commit("remove_array_item", { name: "workouts", data: [workout.id] });
            }
          })
        });
      })
    });

    //assign
    axios.post("/programs/" + payload.program_id + "/assign_job", payload.params)
      .then(resp => {
        if (resp.data.success == 0) {
          dispatch("getPrograms");

          if (vm) vm.$vs.notify({
            title: '成功',
            text: "应用成功",
            color: 'success',
            position: 'top-right'
          })
        }
      })
      .catch(err => {
        // console.log(err.response.data.msg)
        if (vm) vm.$vs.notify({
          title: '错误',
          text: err.response.data.msg,
          color: 'danger',
          position: 'top-right'
        })
      })
  },

  // ////////////////////////////////////////////
  // team
  // ////////////////////////////////////////////
  getTeam({ commit, state }, payload) {
    axios.get("/organizations/" + payload + "/trainers")
      .then(resp => {
        // console.log(resp.success)
        if (resp.data["trainers"]) {
          commit("update_array", { name: "trainers", data: resp.data["trainers"], reset: true })
          state.trainers.forEach(itm => {
            axios.get("/trainers/" + itm.id + "/clients")
              .then(resp => {
                if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] })
                if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] })
              })
          })
        }
      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  getClientWorkout({ commit }, payload) {
    axios.get("/workouts", {
      params: {
        client_id: payload.client.id,
        start_date: DTUtil.getDueDate(payload.range.firstDay),
        end_date: DTUtil.getDueDate(payload.range.lastDay),
        limit: 1000,
        order: "due_ASC"
      }
    })
      .then(resp => {
        // console.log(resp.success)
        if (resp.data["users"]) commit("update_array", { name: "users", data: resp.data["users"] })
        if (resp.data["clients"]) commit("update_array", { name: "clients", data: resp.data["clients"] })
        if (resp.data["comments"]) commit("update_array", { name: "comments", data: resp.data["comments"] })
        if (resp.data["group_assignments"]) commit("update_array", { name: "group_assignments", data: resp.data["group_assignments"] })
        if (resp.data["organizations"]) commit("update_array", { name: "organizations", data: resp.data["organizations"] })
        if (resp.data["workout_items"]) commit("update_array", { name: "orkout_items", data: resp.data["orkout_items"] })
        if (resp.data["workouts"]) commit("update_array", { name: "workouts", data: resp.data["workouts"] })

      })
    // .catch(err => {
    //   // console.log(err)
    // })
  },

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////
  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width);
  },
  updateI18nLocale({ commit }, locale) {
    commit('UPDATE_I18N_LOCALE', locale);
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },
  updateUserRole({ commit }, val) {
    commit('UPDATE_USER_ROLE', val);
  },
  updateWindowWidth({ commit }, width) {
    commit('UPDATE_WINDOW_WIDTH', width);
  },


  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },
}

export default actions
