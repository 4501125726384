/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: "default",
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: themeConfig.theme || 'light',
  navbarSearchAndPinList: navbarSearchAndPinList,
  themePrimaryColor: colors.primary,
  starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  navPageIdx:0,
  adminNavPageIdx:0,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  windowWidth: null,

  // 当前登录教练(trianer)
  activeTrainer: null,

  // 当前登录用户(user 教练的user信息)
  activeUser: null,

  // 当前组织(organization)
  activeOrganization: null,

  // 组织列表(当前教练所属组织)
  organizations: [],

  // 用户列表
  users: [],

  // 客户列表
  clients: [],

  // 教练列表
  trainers: [],

  // 客户分组
  groups: [],

  // 客户所属分组
  group_assignments: [],

  // 训练计划列表
  workouts: [],

  // 训练集合列表
  workout_items: [],

  // 评论列表
  comments: [],

  // 标准训练计划模板
  programs: [],

  // 标准训练计划模板指派
  program_assignments: [],

  // 标准练习
  exercises: [],

  // 标准热身
  warmups:[],

  // 标准收式
  cooldowns:[],

  // 复制得workout
  workoutCopy: null
}

export default state
