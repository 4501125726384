export default {
    status: '',
    token: localStorage.getItem('token') || '',
    activeUser : JSON.parse(localStorage.getItem('userInfo')) || null,
    role: ''
}

// export default {
//   token: localStorage.getItem('token') || null,
//   activeUser : JSON.parse(localStorage.getItem('userInfo')) || null,
//   role: localStorage.getItem('userRole') || null,
//   status: localStorage.getItem('token')?true:false,
// }
